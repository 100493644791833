import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Material Icons
import ErrorIcon from '@material-ui/icons/Error';
import RefreshIcon from '@material-ui/icons/Refresh';
// Custom Components
//import BookmarkButton from '../BookmarkButton';
import { withFirebase } from '../Firebase';
import { SearchedPikS } from "./PikS";
// import { sampleData } from "./sampleData";

const ErrorMessages = {
    'NO_CV_ERROR': "You don't have a CV uploaded. You can upload a CV by clicking the 'EDIT' button on your Profile Card.",
    'NO_PIKS_ERROR': 'Sorry, We could not find any PikS for you. Please try again later.',
    //'PROCCESSING_CV': 'Our apologies. \nWe are still processing your CV please try again in a few minutes'
}

function SugestedPiks(props) {
    var display;
    const jobSearch = props.firebase.functions.httpsCallable('getSuggestedPiks');  // Get Suggestions from Firebase via httpsCallable function
    //const testJobData = require('./test_data.json');
    const dbRef = props.firebase.db.ref(`saved_piks/${props.uid}`);
    const { enqueueSnackbar } = useSnackbar();
    const [SugestedPiks, setSugestedPiks] = useState([]);
    const [IsLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const classes = useStyles();

    function GetPiks(uid) {
        setIsLoading(true);
        // Do Check for User's Doc 
        if (!props.userDoc) {
            setError({ error: 'No CV uploaded', message: ErrorMessages.NO_CV_ERROR });
            setIsLoading(false);
        } else if (props.userDoc.processing) {
            setError({ error: 'CV being processed', message: ErrorMessages.PROCCESSING_CV });
            setIsLoading(false);
        } else {
            // Comment out testJobSearch() for uncomment jobSearch() when testing is over 
    
            //return testJobSearch(uid) // Test
            return jobSearch(uid) // Search
                .then(res => {
                    if (res.data == null) {
                        setError({ error: 'No PikS', message: ErrorMessages.NO_PIKS_ERROR });
                        setIsLoading(false);
                    } else {
                        console.log(res.data);
                        // Analytics
                        props.firebase.analytics.logEvent('suggested_results_loaded');
                        ////////////////////////////////////////
                        setSugestedPiks([...res.data]);
                        setError(null);
                        setIsLoading(false);
                    }
                })
                .catch(err => {
                    setError(err);
                    setIsLoading(false);
                    console.log(err.message);
                });
        }
    }

    const refreshResults = () => {
        // Analytics
        props.firebase.analytics.logEvent('refresh_search_results');
        //////////////////////////////////////;
        GetPiks(props.uid);
    }
    function savePik(pik) {
        const pikKey = dbRef.push()
        pikKey.update(pik)
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('save_pik');
                //////////////////////////////
                enqueueSnackbar("Saved Pik", {
                    variant: 'info',
                });
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar(`We had an Error saving your Pik. Please try again.\n${err}`, {
                    variant: 'error',
                });
            });
    }
    // function copyAll() {
    //     // Analytics
    //     props.firebase.analytics.logEvent('copy_all');
    //     //////////////////////////////
    //     // console.log('Copy All');
    //     var copyText = '';
    //     SugestedPiks.forEach(item => {
    //         copyText += item.link + '\n'
    //     })
    //     navigator.clipboard.writeText(copyText);
    //     enqueueSnackbar("All Links Copied!", {
    //         variant: 'info',
    //     });
    // }
    // function saveAll() {
    //     var saveList = {};
    //     var updatedPiks = SugestedPiks.map((pik) => {
    //         saveList[dbRef.push().key] = pik;
    //         return { ...pik, saved: true }
    //     });
    //     dbRef.update(saveList)
    //         .then(() => {
    //             // Analytics
    //             props.firebase.analytics.logEvent('save_all');
    //             //////////////////////////////
    //             SugestedPiks(updatedPiks);
    //             enqueueSnackbar("Saved all PikS", {
    //                 variant: 'info',
    //             });
    //         }).catch(err => {
    //             enqueueSnackbar(err.message, {
    //                 variant: 'error',
    //             });
    //         });
    // }

    // async function testJobSearch(uid) {
    //     return {data: testJobData};
    // }

    useEffect(() => {
        GetPiks(props.uid);
    }, [props.uid, props.userDoc]);

    if (error) {
        display = (
            <Error
                err={error}
                styling={{
                    card: classes.error_card,
                    icon: classes.error_icon,
                    message: classes.error_message,
                    action: classes.error_action
                }}
                reTry={refreshResults}
            />
        );
    } else {
        display = (
            <SearchedPikS
                latestPiks={SugestedPiks}
                analytics={props.firebase.analytics}
                refresh={refreshResults}
                savePik={savePik}
            />
        );
    }

    // create a grid for the latest pik's page
    return (
        <React.Fragment>

            {/* show loading and save button */}
            <div className={classes.root}>
                <Typography variant='h4' component='h2' gutterBottom >Recent results in your area</Typography>
                
                {/* create bookmark save all button if not loading */}
                {/* {!IsLoading && SugestedPiks.length > 0 ? (
                    <div className={classes.action_bar}>
                        <div className={classes.saveAll_button} onClick={saveAll}>
                            <BookmarkButton 
                                className={classes.saveAll_bookmarkbutton}
                                text='Save all'
                                color='secondary'
                                center 
                            />
                        </div>
                        <Button
                            className={classes.copyAll_button}
                            variant='contained'
                            onClick={copyAll}
                        > Copy All </Button>
                    </div>
                ) : (null)} */}

                <Grid container spacing={4} justify='flex-start'>
                    {IsLoading ?
                        (<Grid item className={classes.loading}>
                            <Typography gutterBottom >LOADING...</Typography>
                            <CircularProgress color='secondary' />
                        </Grid>)
                        :
                        (display)}
                </Grid>
            </div>
        </React.Fragment>
    )
}

function Error(props) {

    return (
        <Grid item xs={8} align='center' >
            <Card elevation={6} className={props.styling.card} >
                <CardContent className={props.styling.message} >
                    <ErrorIcon className={props.styling.icon} />
                    <Typography variant='h5' color='error' gutterBottom >{props.err.error}</Typography>
                    <Typography variant='body1' component='p' >{props.err.message}</Typography>
                </CardContent>
                <CardActions  >
                    <Button
                        className={props.styling.action}
                        variant='contained'
                        startIcon={<RefreshIcon />}
                        onClick={props.reTry}
                    >
                        Try Again
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default withFirebase(SugestedPiks);

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '70%',
        minHeight: '70vh',
        textAlign: 'start',
        '& >h2': {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: theme.palette.grey.main,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    action_bar: {
        zIndex: 6,
        width: '20vw',
        position: 'fixed',
        //top: '50px',
        //display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'space-between',
        bottom: '10vh',
        //left: '0vw',
        //minHeight: 'fit-content',
        //margin: '500px 0 0 40px',
        [theme.breakpoints.down('1680.05')]: {
            //left: '-21rem',
        },
        [theme.breakpoints.down('1600.05')]: {
            //left: '-20rem',
        },
        [theme.breakpoints.down('1440.05')]: {
            //left: '-18rem',
        },
        [theme.breakpoints.down('1366.05')]: {
           //left: '-17rem',
        },
        [theme.breakpoints.down('1024.05')]: {
            left: '-24vw',
        },
        [theme.breakpoints.down('md')]: {
            
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '2.5rem',
            margin: theme.spacing(2, 0),
            position: 'fixed',
            left: 0,
            bottom: '10vh',
            flexDirection: 'row',
            justifyContent: 'space-between',

        },
    },
    saveAll_button: {
        height: '2.5rem',
        width: '105%',
        bottom: theme.spacing(1),
        left: '-10%',
        position: 'relative',

        '& svg':{
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            left: 0,
            bottom: 0,
            textAlign: 'end',
            marginRight: theme.spacing(1),
        }
    },
    copyAll_button:{
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
    },
    loading: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error_card: {
        maxWidth: '65%',
        borderRadius: theme.shape.borderRadius,
        paddingBottom: theme.spacing(2),
    },
    error_icon: {
        fontSize: 80,
        color: theme.palette.error.main,
    },
    error_action: {
        margin: 'auto',
        backgroundColor: theme.palette.error.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
}));