import React from 'react';
import { useLocation } from "react-router-dom";
// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//Custom Imports
import { ReactComponent as SignInImg } from '../assets/sign-in-img.svg'
import SignInForm from '../components/SignIn/signInForm';
import { SignUpForm } from '../components/SignUp';
import { SignInLinkedin, SignInGoogle } from '../components/ProvidersSignIn';

export default function SignInPage() {
    const classes = useStyles();
    const location = useLocation();
    console.log(location);

    return (
        <main className='page-root'>
            <div className={classes.content_root}>
                <div className={classes.signIn_img}>
                    <SignInImg className={classes.signIn_img_source}/>
                </div>
                <div className={classes.form_root}>
                    {location.pathname === '/signup' ? <SignUpForm /> : <SignInForm />}

                    <Typography variant='h5' component='span'>
                        OR
                    </Typography>
                    <div className={classes.provider_buttons}>
                        {/*<SignInLinkedin />*/}
                        <SignInGoogle />
                    </div>
                </div>
            </div>
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(5, 4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            justifyContent: 'center',
        },
    },
    signIn_img: {
        position: 'absolute',
        top: '-6vh',
        left: '-1%',
        width: '55%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    signIn_img_source:{
        '& #Head_1':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #ArmRight_1':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #ArmLeft_1':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Leg_1':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Leg_2':{
            fill: 'rgb(200, 150, 115)',
        },
    },
    form_root: {
        position: 'relative',
        maxWidth: '45vw',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        '& >span': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: theme.spacing(0, 2),
            '&::before, &::after': {
                content: '""',
                margin: 'auto 1rem',
                borderBottom: '1px solid',
                flex: '1 1',
            },
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '85%',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            padding: theme.spacing(2, 0),
        },
    },
    provider_buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '80%',
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },

}))