// Packages Imports
import React, { useState } from 'react'
import { useSnackbar } from 'notistack';
// Material-ui Components
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Material-ui Icons
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
// Custom Imports
import { withFirebase } from '../Firebase';
import GetLocation from "./getLocation";
import './dropDownStyles.css'

// Search Bar
const locationSearchBar = require('./locationSearchBar');
const SearchBar = locationSearchBar.Search;

const useStyles = makeStyles((theme) => ({
    updateButton: {
        backgroundColor: theme.palette.primary.main,
        color:'black',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        VarientupdateButton: {
            backgroundColor: theme.palette.grey.main,
            color:'black',
            '&:hover': {
                backgroundColor: theme.palette.grey.light,
            },
        },
    }
}));

function AddLocationBase(props) {

    const addlocation = () => {
        console.log("Add Location Clicked");

        // Get User's Location
        GetLocation({ uid: props.authuser.uid, firebase: props.firebase })
            .then(res => {
                console.log(res.data);
                props.firebase.user(props.authuser.uid)
                    .update(res.data)
                    .then(() => {
                        // Analytics
                        // props.firebase.analytics.logEvent('current_location_added');
                        //////////////////////////////////////
                        console.log("Data Written");
                    });
            })
    }

    return (
        <Button variant='outlined' onClick={addlocation} >Add Location</Button>
    )
}

function UpdateToCurrentLocationBase(props) {
    const classes = useStyles();
    const [Saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const addlocation = () => {
        console.log("Add Location Clicked");
        setSaving(true);

        // Get User's Location
        GetLocation({ firebase: props.firebase })
            .then(res => {
                // console.log(res.data);
                props.firebase.user(props.authuser.uid)
                    .update(res.data)
                    .then(() => {
                        // Analytics
                        props.firebase.analytics.logEvent('current_location_updated');
                        //////////////////////////////////////
                        setSaving(false);
                        enqueueSnackbar("Update Successful", {
                            variant: 'success',
                        });
                        console.log("Data Written");
                    })
                    .catch(err => {
                        setSaving(false);
                        console.log(err)
                    })
            })

    }

    return (
        <Button
            disableElevation
            className={classes.updateButton}
            variant='contained'
            startIcon={<AddLocationIcon />}
            onClick={addlocation}
        >
            {!Saving ? ("Add Current Location") : ("Saving...")}
        </Button>
    )
}
// Variants
function VariantUpdateToCurrentLocationBase(props) {
    const classes = useStyles();
    const [Saving, setSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const addlocation = () => {
        console.log("Add Location Clicked");
        setSaving(true);

        // Get User's Location
        GetLocation({ firebase: props.firebase })
            .then(res => {
                // console.log(res.data);
                props.firebase.user(props.authuser.uid)
                    .update(res.data)
                    .then(() => {
                        // Analytics
                        props.firebase.analytics.logEvent('current_location_updated');
                        //////////////////////////////////////
                        setSaving(false);
                        enqueueSnackbar("Update Successful", {
                            variant: 'success',
                        });
                        console.log("Data Written");
                    })
                    .catch(err => {
                        setSaving(false);
                        console.log(err)
                    })
            })

    }

    return (
        <Button
            disableElevation
            className={classes.VarientupdateButton}
            variant='contained'
            startIcon={<AddLocationIcon />}
            onClick={addlocation}
        >
            {!Saving ? ("Add Current Location") : ("Saving...")}
        </Button>
    )
}

function AddPreferedLocationBase(props) {
    const [Country, setCountry] = useState(props.authuser.location.country ? (props.authuser.location.country) : (''));
    const [Region, setRegion] = useState(props.authuser.location.region ? (props.authuser.location.region) : (''));

    function selectLocation(val) {
        if (val) {
            setCountry(val.country);
            setRegion(val.region);
        } else {
            console.log(`Pass`)
        }

    }

    function save() {
        return props.firebase.userSearchParam(props.authuser.uid).update({
            'altLocation.country': Country,
            'altLocation.region': Region
        }).catch(err => console.error(err));
    }
    function cancel() {
        setCountry(props.authuser.location.country ? (props.authuser.location.country) : (''));
        setRegion(props.authuser.location.region ? (props.authuser.location.region) : (''));
    }

    return (
        <Container maxWidth='sm'>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography align='center' variant='subtitle2'>
                        Use this search bar to change the location we should use when finding your Piks.
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchBar country={Country} region={Region} updateLocation={(val) => selectLocation(val)} />
                    <Typography variant='body1' gutterBottom>Region: {Region}</Typography>
                    <Typography variant='body1' gutterBottom>Country: {Country}</Typography>
                </Grid>
                <Grid item>
                    <ButtonGroup variant='text' fullWidth>
                        <Button onClick={save} >
                            <SaveIcon />
                        </Button>
                        <Button onClick={cancel} >
                            <DeleteIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Container>
    )
}

const AddLocation = withFirebase(AddLocationBase)
const AddPreferedLocationForm = withFirebase(AddPreferedLocationBase)
const UpdateCurrentLocation = withFirebase(UpdateToCurrentLocationBase)
const VariantUpdateCurrentLocation = withFirebase(VariantUpdateToCurrentLocationBase)

export { AddLocation, AddPreferedLocationForm, UpdateCurrentLocation, VariantUpdateCurrentLocation }
