const FAQs = [
    {
        q: 'What is PikR?',
        a: 'We’re here to get you more access to more job opportunities more quickly.'
    },
    {
        q: 'How does PikR Work?',
        a: 'Our Machine Learning Tool uses your CV to provide relevant search results from the worlds leading job boards, you take your Pik.'
    },
    {
        q: 'Why use PikR?',
        a: 'It’s the easiest way to source relevant job opportunities, no more search strings, no more multiple sites and laborious browsing.'
    },
    {
        q: 'How often can I use PikR?',
        a: 'There are thousands of new job opportunities posted daily, use PikR daily to stay up to date with the latest jobs suited to your profile.'
    },
    {
        q: 'Why am I not getting results?',
        a: 'It’s possible but unlikely there’s nothing suitable for you posted online right now, make sure we have your most recently updated CV uploaded and ensure all your skills, experience and talents are detailed in full for the best results.'
    },
    {
        q: 'What sites does PikR query?',
        a: `We search:
        linkedin.com,
        totaljobs.com,
        cv-library.co.uk,
        Monster,
        Indeed.com,
        executiveplacements.com,
        pnet.co.za,
        careerjunction.co.za`
    },
]

export default FAQs;