import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Material-UI Components
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from './appTheme';
// Pages
import AccountPage from '../pages/Account';
import AdminPage from '../components/Pages_old/Admin';
import PasswordForgetPage from '../components/PasswordForget';
//import ProfilePage from '../Pages/Profile';
import LandingPage from "../pages/Landing";
import SignInPage from '../pages/sign-in';
import Dashboard from '../pages/Dashboard';
import Saved from '../pages/Saved';
import FAQPage from '../pages/FAQ';
import ExtensionPage from '../pages/extension';
import PrivacyPolicyPage from '../pages/privacy-policy';
import UserAgreementPage from '../pages/user-agreement';

import UploadCV from '../pages/UploadCV';

// Custom Components
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { withAuthentication } from '../components/Session';
// Constants
import * as ROUTES from '../constants/routes';


const App = () => (
  <ThemeProvider theme={Theme} >
    <CssBaseline />
    <Router>
      <Navigation />

      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.SIGN_UP} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.HOME} component={Dashboard} />
      <Route path={ROUTES.SAVED} component={Saved} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.UPLOADCV} component={UploadCV} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route path={ROUTES.FAQ} component={FAQPage} />
      <Route path={ROUTES.EXTENSION} component={ExtensionPage} />
      <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
      <Route path={ROUTES.USER_AGREEMENT} component={UserAgreementPage} />

      {/* <Route path='/new' ></Route> */}

      <Footer />
    </Router>
  </ThemeProvider>
);

export default withAuthentication(App);

