import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// Material-UI Components
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead.
`;

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Analytics
        this.props.firebase.analytics.logEvent('login', {
          method: 'Facebook'
      });
      //////////////////////////////
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });
    event.preventDefault();
  };

  render() {
    var { classes } = this.props;
    const { error } = this.state;
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Button className={classes.button} type="submit">Sign In with Facebook</Button>
        </form>
        <div>
          {error && <p>{error.message}</p>}
        </div>
      </div>
    );
  }
}

const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  bottom: {
    color: 'lightGrey',
  },
  top: {
    color: '#7A43BC',
    animationDuration: '800ms',
    position: 'absolute',
  },
  circle: {
    strokeLinecap: 'round',
  },
  button: {
    backgroundColor: '#3b5998',
    color: '#fff'
  }
});

const SignInFacebook = compose(
  withStyles(useStyles),
  withRouter,
  withFirebase,
)(SignInFacebookBase);

export default SignInFacebook;