import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
//Material-ui Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';

//Material-ui Icons
import DeleteIcon from '@material-ui/icons/Delete';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SaveIcon from '@material-ui/icons/Save';

import { withFirebase } from '../Firebase';

function SocialFormBase(props) {
    const INITIAL_STATE = ('socialPages' in props.authuser ? {
        facebook: props.authuser.socialPages.facebook,
        linkedin: props.authuser.socialPages.linkedin,
        github: props.authuser.socialPages.github,
        personal: props.authuser.socialPages.personal,
    } : {
            facebook: '',
            linkedin: '',
            github: '',
            personal: ''
        });
    const { enqueueSnackbar } = useSnackbar();

    const [Links, setLinks] = useState(INITIAL_STATE);

    const onChange = (event) => {
        const { id, value } = event.target
        setLinks(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    function saveDetails() {
        console.log('saving');
        props.firebase
            .user(props.authuser.uid)
            .update({ socialPages: Links })
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('socialLinks_updated')
                ////////////////////////
                enqueueSnackbar("Update Successful", { 
                    variant: 'success',
                });
            });
    }
    function cancel() {
        console.log('cancel');
        setLinks(INITIAL_STATE);
    }

    return (
        <Container maxWidth='sm'>
            <Grid container direction='column' alignItems="stretch" spacing={2}>
                <Grid item >
                    <FormControl fullWidth>
                        <InputLabel >LinkedIn</InputLabel>
                        <Input
                            margin="dense"
                            id="linkedin"
                            type="text"
                            placeholder='https://www.linkedin.com/in/username'
                            value={Links.linkedin}
                            onChange={event => onChange(event)}
                            startAdornment={<InputAdornment position="start">
                                <LinkedInIcon />
                            </InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item >
                    <FormControl fullWidth >
                        <InputLabel >Facebook</InputLabel>
                        <Input
                            margin="dense"
                            id="facebook"
                            label="Facebook"
                            type="text"
                            placeholder='https://www.facebook.com/username'
                            value={Links.facebook}
                            onChange={event => onChange(event)}
                            startAdornment={<InputAdornment position="start">
                                <FacebookIcon />
                            </InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item >
                    <FormControl fullWidth >
                        <InputLabel >GitHub</InputLabel>
                        <Input
                            margin="dense"
                            id="github"
                            label="GitHub"
                            type="text"
                            placeholder='https://github.com/username'
                            value={Links.github}
                            onChange={event => onChange(event)}
                            startAdornment={<InputAdornment position="start">
                                <GitHubIcon />
                            </InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item >
                    <FormControl fullWidth >
                        <InputLabel >Personal Site</InputLabel>
                        <Input margin="dense"
                            id="personal"
                            label="Personal Site"
                            type="text"
                            placeholder='https://www.yoursite.com'
                            value={Links.personal}
                            onChange={event => onChange(event)}
                            startAdornment={<InputAdornment position="start">
                                <LanguageIcon />
                            </InputAdornment>}
                        />
                    </FormControl>
                </Grid>
                <Grid item >
                    <ButtonGroup fullWidth variant='text' >
                        <Button onClick={saveDetails}>
                            <SaveIcon />
                        </Button>
                        <Button onClick={cancel}>
                            <DeleteIcon />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Container>
    )
}

const SocialLinksForm = withFirebase(SocialFormBase);

export { SocialLinksForm }
