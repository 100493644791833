import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Custom Components
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

export default function PasswordForgetPage() {
  const classes = useStyles();

  return (
    <main className='page-root-forgotPassword'>
      <div className={classes.content_root}>
        <Container maxWidth='md'>
          <Paper >
            <Grid container spacing={3} direction="column" alignItems="center">
              <Grid item>
                <Typography variant='h3' align='center' gutterBottom >Forgot Your Password?</Typography>
                <Typography variant='body1' align='center' gutterBottom >Enter your email below and we will send you an email to reset your password.</Typography>
              </Grid>
              <Grid item>
                <PasswordForgetForm />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </main>
  );
}

const useStyles = makeStyles(theme => ({
  content_root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(5, 4, 0),
      [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(4, 2),
          justifyContent: 'center',
      },
  },
}))

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        // Analytics
        this.props.firebase.analytics.logEvent('forgot_password_request');
        //////////////////////////////
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <Grid container spacing={1} direction="column" alignItems="center">
          <Grid item>
            <TextField
              size="small"
              variant="outlined"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" disabled={isInvalid} type="submit">
              Submit Password Reset
            </Button>
          </Grid>
        </Grid>

        {error && <Typography color='secondary' variant='subtitle1' align='center' gutterBottom>{error.message}</Typography>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
);

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
