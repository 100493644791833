import React from 'react';
//import { Route, Switch, useLocation } from "react-router-dom";
// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Custom Components
import Accordion from '../components/Accordion';
import FAQs from '../constants/faq-messages';

export default function ExtensionPage() {
    const classes = useStyles();  return (
        <main className='page-root'>
            <div className={classes.content_root}>
            
            {/* FAQ Section */}
            <div className={classes.faq_section + ' MuiPaper-rounded'}>
                <Typography variant='h3' component='h2' className={classes.faq_title}>FAQs</Typography>

                <div className={classes.faq_accordion_root}>
                    <Accordion Items={FAQs} />
                </div>

            </div>
            </div>
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(5, 4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            justifyContent: 'center',
        },
    },
    faq_section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(2, 4),
        backgroundColor: theme.palette.grey.main,
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            padding: theme.spacing(4, 2),
        },
    },
    faq_title: {
        ...theme.typography.sectionTitle,
    },
    faq_accordion_root: {
        alignSelf: 'center',
        margin: theme.spacing(2, 0),
        '& p': {
            color: theme.palette.common.white,
        },
    },
}))