export const LANDING = '/';
export const PRIVACY_POLICY = '/privacy-policy';
export const USER_AGREEMENT = '/user-agreement';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/dashboard';
export const EXTENSION = '/extension';
export const FAQ = '/faq';
export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const SAVED = '/saved-piks';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const UPLOADCV = '/UploadCV';
export const MOVEPIKS = '/MovePiks';

