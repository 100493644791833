import React from 'react';
// Material-ui Imports
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function UserAgreementPage() {
    const classes = useStyles();
    return (
        <main className='page-root'>
            <div className={classes.content_root}>
                <Typography
                    className={classes.title}
                    variant='h3'
                    compoent='h2'
                    gutterBottom >
                    PikR User Agreement
                </Typography>
                <Paper className={classes.page}>
                    <ol>
                        <Typography variant='body2' component='li' gutterBottom>Introduction
                            <ol>
                                <Typography value="" variant='body2' component='li' gutterBottom>Contract
                                            <Typography variant='body2' component='li' gutterBottom>
                                                When you use our Services you agree to all of these terms. Your use of our  Services     is also subject to
                                                our Privacy Policy, which covers how we collect, use, share, and store your     personal    information.
                                                You agree that by setting up a PikR account or similar, registering, accessing  or   using our
                                                services, you are agreeing to enter into a legally binding contract with PikR   (even     if you are using our
                                                Services on behalf of a company). If you do not agree to this contract (    “Contract”  or “User
                                                Agreement”), do not set up an account (or similar) and do not access or     otherwise   use any of our
                                                Services. If you wish to terminate this contract, at any time you can do so by      closing your account
                                                and no longer accessing or using our Services.
                                            </Typography>                               
                                        Services
                                            <Typography variant='body2' component='li' gutterBottom>
                                                This Contract applies to PikR.tech, PikR-branded apps and other PikR-related sites,     apps,
                                                communications and other services that state that they are offered under this   Contract (“Services”),
                                                including the offsite collection of data for those Services, such as our ads and the    “Apply with PikR”
                                                and other plugins. Registered users of our Services are “Members” and unregistered  users are
                                                “Visitors”.
                                            </Typography>    
                                        PikR
                                            <Typography variant='body2' component='li' gutterBottom>
                                                You are entering into this Contract with PikR (also referred to as “we” and “us”).
                                                You are entering into this Contract with PikR (PTY) Limited (“PikR”) and PikR Ltd.  will be the
                                                controller of your personal data provided to, or collected by or for, or processed  in connection with
                                                our Services.
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                This Contract applies to Members and Visitors.
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                As a Visitor or Member of our Services, the collection, use and sharing of your     personal data is
                                                subject to our Privacy Policy (which includes our Cookie Policy and other documents     referenced in
                                                this Privacy Policy) and updates.
                                            </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Members and Visitors
                                            <Typography variant='body2' component='li' gutterBottom>
                                                When you register and join the PikR Services, you become a Member. If you have  chosen not to
                                                register for our Services, you may access certain features as a “Visitor.”
                                            </Typography>
                                </Typography>   
                                <Typography variant='body2' component='li' gutterBottom>Change
                                            <Typography variant='body2' component='li' gutterBottom>
                                                We may make changes to the Contract.
                                                We may modify this Contract and our Privacy Policy from time to time. If we make    material changes
                                                to it, we will provide you notice through our Services, or by other means, to   provide you the
                                                opportunity to review the changes before they become effective. We agree that   changes cannot be
                                                retroactive. If you object to any changes, you may close your account. Your     continued use of our
                                                Services after we publish or send a notice about our changes to these terms means   that you are
                                                consenting to the updated terms as of their effective date.
                                            </Typography>
                                </Typography>
                            </ol>
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>Obligations
                            <ol>
                                <Typography variant='body2' component='li' gutterBottom>Service Eligibility
                                    <Typography variant='body2' component='li' gutterBottom> 
                                        Here are some promises that you make to us in this Contract:
                                        You’re eligible to enter into this Contract and you are at least our “Minimum Age.”
                                        The Services are not for use by anyone under the age of 16.
                                        To use the Services, you agree that: (1) you must be the “Minimum Age” (described   below)    or older;
                                        (2) you will only have one PikR account, which must be in your real name; and (3)   you   are not
                                        already restricted by PikR from using the Services. Creating an account with false      information is a
                                        violation of our terms, including accounts registered on behalf of others or    persons    under the age of
                                        16.
                                        “Minimum Age” means 16 years old. However, if law requires that you must be older   in    order for
                                        PikR to lawfully provide the Services to you without parental consent (including    using  of your
                                        personal data) then the Minimum Age is such older age.
                                    </Typography>
                                </Typography>

                                <Typography variant='body2' component='li' gutterBottom>Your Account
                                    <Typography variant='body2' component='li' gutterBottom>
                                        You will keep your password a secret.
                                        You will not share an account with anyone else and will follow our rules and the law.
                                        Members are account holders. You agree to: (1) use a strong password and keep it confidential; (2)
                                        not transfer any part of your account and (3) follow the law. You are responsible for anything that
                                        happens through your account unless you close it or report misuse.
                                        As between you and others (including your employer), your account belongs to you. However, if the
                                        Services were purchased by another party for you to use, the party paying for such Service has the
                                        right to control access to and get reports on your use of such paid Service; however, they do not
                                        have rights to your personal account.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Payment
                                        <Typography variant='body2' component='li' gutterBottom>
                                            You’ll honour your payment obligations and you are okay with us storing     your payment information.
                                            You understand that there may be fees and taxes that are added to our   prices.
                                            Refunds are subject to our policy.
                                            If you buy any of our paid Services (“Premium Services”), you agree to  pay us the applicable fees and
                                            taxes and to additional terms specific to the paid Services. Failure to     pay these fees will result in the
                                            termination of your paid Services. Also, you agree that:
                                            <ul>
                                                <Typography variant='body2' component='li' gutterBottom>
                                                    Your purchase may be subject to foreign exchange fees or  differences in prices based on
                                                location (e.g. exchange rates).
                                                </Typography>
                                                <Typography variant='body2' component='li' gutterBottom>
                                                    We may store and continue billing your payment method (e.g. credit    card) even after it has
                                                expired, to avoid interruptions in your Services and to use to pay  other Services you may
                                                buy.
                                                </Typography>
                                                <Typography variant='body2' component='li' gutterBottom>
                                                    If you purchase a subscription, your payment method automatically     will be charged at the
                                                start of each subscription period for the fees and taxes applicable     to that period. To avoid
                                                future charges, cancel before the renewal date. Learn how to cancel     or suspend your
                                                Premium Services.
                                                </Typography>
                                                <Typography variant='body2' component='li' gutterBottom>
                                                    All of your purchases of Services are subject to PikR’s refund    policy.
                                                </Typography>
                                                <Typography variant='body2' component='li' gutterBottom>
                                                    We may calculate taxes payable by you based on the billing    information that you provide us
                                                    at the time of purchase.
                                                </Typography>
                                            </ul>
                                        </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Notices and Messages
                                    <Typography variant='body2' component='li' gutterBottom>
                                        You’re okay with us providing notices and messages to you through our websites, apps, and contact
                                        information. If your contact information is out of date, you may miss out on important notices.
                                        You agree that we will provide notices and messages to you in the following ways: (1) within the
                                        Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical
                                        address). You agree to keep your contact information up to date.
                                        Please review your settings to control and limit messages you receive from us.
                                    </Typography>
                                </Typography>

                            </ol>
                        </Typography>
                        
                        <Typography variant='body2' component='li' gutterBottom>Rights and Limits
                            <ol>
                                <Typography variant='body2' component='li' gutterBottom>Your License to PikR
                                        <Typography variant='body2' component='li' gutterBottom>
                                        You own all of the content, feedback and personal information you provide to us, but you also grant
                                        us a non-exclusive license to it.
                                        We’ll honour the choices you make about who gets to see your information and content, including
                                        how it can be used for ads.
                                        As between you and PikR, you own the content and information that you submit or post to the
                                        Services, and you are only granting PikR and our affiliates the following non-exclusive license:
                                        A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and
                                        process, information and content that you provide through our Services and the services of others,
                                        without any further consent, notice and/or compensation to you or others. These rights are limited
                                        in the following ways:
                                        </Typography>
                                    <ul>    
                                        <Typography variant='body2' component='li' gutterBottom>
                                            You can end this license for specific content by deleting such content from the Services, or
                                            generally by closing your account, except (a) to the extent you shared it with others as part
                                            of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it
                                            takes to remove from backup and other systems.
                                        </Typography>
                                        <Typography variant='body2' component='li' gutterBottom>
                                            We will not include your content in advertisements for the products and services of third
                                            parties to others without your separate consent (including sponsored content). However, we
                                            have the right, without payment to you or others, to serve ads near your content and
                                            information, and your actions may be visible and included with ads, as noted in the Privacy
                                            Policy. If you use a Service feature, we may mention that with your name or photo to
                                            promote that feature within our Services, subject to your settings.
                                        </Typography>
                                        <Typography variant='body2' component='li' gutterBottom>
                                            We will get your consent if we want to give others the right to publish your content beyond
                                            the Services.
                                        </Typography>
                                        <Typography variant='body2' component='li' gutterBottom>
                                            While we may edit and make format changes to your content (such as translating or
                                            transcribing it, modifying the size, layout or file type or removing metadata), we will not
                                            modify the meaning of your expression.
                                        </Typography>
                                        <Typography variant='body2' component='li' gutterBottom>
                                            Because you own your content and information and we only have non-exclusive rights to it,
                                            you may choose to make it available to others, including under the terms of a Creative
                                            Commons license.
                                        </Typography>
                                        <Typography variant='body2' component='' gutterBottom>
                                            You and PikR agree that if content includes personal data, it is subject to our Privacy Policy.
                                            You and PikR agree that we may access, store, process and use any information and personal data
                                            that you provide in accordance with, the terms of the Privacy Policy and your choices (including
                                            settings).
                                        </Typography>
                                        <Typography variant='body2' component='' gutterBottom>
                                            By submitting suggestions or other feedback regarding our Services to PikR, you agree that PikR can
                                            use and share (but does not have to) such feedback for any purpose without compensation to you.
                                            You promise to only provide information and content that you have the right to share, and that your
                                            PikR profile will be truthful.
                                            You agree to only provide content or information that does not violate the law nor anyone’s rights
                                            (including intellectual property rights). You also agree that your profile information will be truthful.
                                            PikR may be required by law to remove certain information or content in certain countries.
                                        </Typography>
                                    </ul>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Service Availability
                                    <Typography variant='body2' component='' gutterBottom>
                                        We may change or end any Service or modify our prices prospectively.
                                        We may change, suspend or discontinue any of our Services. We may also modify our prices
                                        effective prospectively upon reasonable notice to the extent allowed under the law.
                                        We don’t promise to store or keep showing any information and content that you’ve posted. PikR is
                                        not a storage service. You agree that we have no obligation to store, maintain or provide you a copy
                                        of any content or information that you or others provide, except to the extent required by applicable
                                        law and as noted in our Privacy Policy.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Other Content, Sites and Apps
                                    <Typography variant='body2' component='' gutterBottom>
                                        Your use of others’ content and information posted on our Services, is at your own risk.
                                        Others may offer their own products and services through our Services, and we aren’t responsible
                                        for those third-party activities.
                                        By using the Services, you may encounter content or information that might be inaccurate,
                                        incomplete, delayed, misleading, illegal, offensive or otherwise harmful. PikR generally does not
                                        review content provided by our Members or others. You agree that we are not responsible for
                                        others’ (including other Members’) content or information. We cannot always prevent this misuse of
                                        our Services, and you agree that we are not responsible for any such misuse. You also acknowledge
                                        the risk that you or your organization may be mistakenly associated with content about others.
                                        PikR may help connect Members offering their services with Members seeking services. PikR does
                                        not perform nor employs individuals to perform these services. You must be at least 18 years of age
                                        to offer, perform or procure these services. You acknowledge that PikR does not supervise, direct,
                                        control or monitor Members in the performance of these services and agree that (1) PikR is not
                                        responsible for the offering, performance or procurement of these services, (2) PikR does not
                                        endorse any particular Member’s offered services, and (3) nothing shall create an employment,
                                        agency, or joint venture relationship between PikR and any Member offering services. If you are a
                                        Member offering services, you represent and warrant that you have all the required licenses and will
                                        provide services consistent with our Professional Community Policies.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Limits
                                    <Typography variant='body2' component='' gutterBottom>
                                        We have the right to limit how you connect and interact on our Services.
                                        PikR reserves the right to limit your use of the Services, including the number of your connections
                                        and your ability to contact other Members. PikR reserves the right to restrict, suspend, or terminate
                                        your account if you breach this Contract or the law or are misusing the Services.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Intellectual Property Rights
                                    <Typography variant='body2' component='' gutterBottom>
                                        We’re providing you notice about our intellectual property rights.
                                        PikR reserves all of its intellectual property rights in the Services. Trademarks and logos used in
                                        connection with the Services are the trademarks of their respective owners. PikR logos and other
                                        PikR trademarks, service marks, graphics and logos used for our Services are trademarks or
                                        registered trademarks of PikR.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Automated Processing
                                    <Typography variant='body2' component='' gutterBottom>
                                        We use data and information about you to make relevant suggestions to you and others.
                                        We use the information and data that you provide and that we have about Members to make
                                        recommendations for connections, content and features that may be useful to you. For example, we
                                        use data and information about you to recommend jobs to you and you to recruiters. Keeping your
                                        profile accurate and up to date helps us to make these recommendations more accurate and
                                        relevant.
                                    </Typography>
                                </Typography>
                            </ol>
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>Disclaimer and Limit of Liability
                            <ol>
                                <Typography variant='body2' component='li' gutterBottom>No Warranty
                                    <Typography variant='body2' component='' gutterBottom>
                                        This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.
                                        PIKR AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES,
                                        INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE,
                                        AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS
                                        AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, PIKR AND ITS
                                        AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED
                                        WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
                                        FOR A PARTICULAR PURPOSE.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Exclusion of Liability
                                    <Typography variant='body2' component='' gutterBottom>
                                        These are the limits of legal liability we may have to you.
                                        TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS PIKR HAS ENTERED INTO A SEPARATE
                                        WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), PIKR, INCLUDING ITS AFFILIATES, WILL
                                        NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS
                                        OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA
                                        (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY
                                        INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
                                        PIKR AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR
                                        ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO PIKR FOR THE
                                        SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $1000.
                                    </Typography>
                                </Typography>
                                <Typography variant='body2' component='li' gutterBottom>Basis of the Bargain; Exclusions
                                    <Typography variant='body2' component='' gutterBottom>
                                        The limitations of liability in this Section 4 are part of the basis of the bargain between you and PikR
                                        and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if PikR
                                        or its affiliates has been told of the possibility of any such damage, and even if these remedies fail
                                        their essential purpose.
                                        These limitations of liability do not apply to liability for death or personal injury or for fraud, gross
                                        negligence or intentional misconduct, or in cases of negligence where a material obligation has been
                                        breached, a material obligation being such which forms a prerequisite to our delivery of services and
                                        on which you may reasonably rely, but only to the extent that the damages were directly caused by
                                        the breach and were foreseeable upon conclusion of this Contract and to the extent that they are
                                        typical in the context of this Contract.
                                    </Typography>
                                </Typography>
                            </ol>
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>Termination
                            <Typography variant='body2' component='' gutterBottom>
                                We can each end this Contract, but some rights and obligations survive.
                                 Both you and PikR may terminate this Contract at any time with notice to the other. On termination,
                                you lose the right to access or use the Services. The following shall survive termination:
                                <ul>
                                    <Typography variant='body2' component='li' gutterBottom>
                                        Our rights to use and disclose your feedback;
                                    </Typography>
                                    <Typography variant='body2' component='li' gutterBottom>
                                        Members and/or Visitors’ rights to further re-share content and information you shared
                                        through the Services;
                                    </Typography>
                                    <Typography variant='body2' component='li' gutterBottom>
                                        Sections 4, 6, 7, and 8.2 of this Contract;
                                    </Typography>
                                    <Typography variant='body2' component='li' gutterBottom>
                                        Any amounts owed by either party prior to termination remain owed after termination.
                                    </Typography>
                                </ul>
                            </Typography>
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>Governing Law and Dispute Resolution
                            <Typography variant='body2' component='' gutterBottom>
                                In the unlikely event we end up in a legal dispute, you and PikR agree to resolve it in South African
                                courts using South African law.
                                This section shall not deprive you of any mandatory consumer protections under the law of the
                                country to which we direct Services to you, where you have your habitual residence.
                                You and PikR both agree that all claims and disputes can be litigated only in the courts in South
                                Africa, and you and PikR each agree to personal jurisdiction in those courts.
                            </Typography>
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>General Terms
                            <Typography variant='body2' component='' gutterBottom>
                                Here are some important details about the Contract.
                                If a court with authority over this Contract finds any part of it unenforceable, you and we agree that
                                the court should modify the terms to make that part enforceable while still achieving its intent. If the
                                court cannot do that, you and we agree to ask the court to remove that unenforceable part and still
                                enforce the rest of this Contract.
                                This Contract (including additional terms that may be provided by us when you engage with a
                                feature of the Services) is the only agreement between us regarding the Services and supersedes all
                                prior agreements for the Services.
                                If we don't act to enforce a breach of this Contract, that does not mean that PikR has waived its right
                                to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of
                                Services) to anyone without our consent. However, you agree that PikR may assign this Contract to
                                its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to
                                this Contract.
                                You agree that the only way to provide us legal notice is at the addresses provided in Section 10.
                            </Typography>
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>PikR Rules
                                <ol strat="a">
                                    <Typography variant='body2' component='li' gutterBottom>You agree that you will:
                                        <ol type="a" start="a">
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Comply with all applicable laws, including, without limitation, privacy laws, intellectual
                                                property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Provide accurate information to us and keep it updated;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Use your real name on your profile; and
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Use the Services in a professional manner.    
                                            </Typography>
                                        </ol>
                                    </Typography>
                                    <Typography variant='body2' component='li' gutterBottom>You agree that you will not:
                                        <ol type="a">
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Create a false identity on PikR, misrepresent your identity, create a Member profile for
                                                anyone other than yourself (a real person), or use or attempt to use another’s account;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Develop, support or use software, devices, scripts, robots or any other means or processes
                                                (including crawlers, browser plugins and add-ons or any other technology) to scrape the
                                                Services or otherwise copy profiles and other data from the Services;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                            Override any security feature or bypass or circumvent any access controls or use limits of the
                                            Service (such as caps on keyword searches or profile views);
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Copy, use, disclose or distribute any information obtained from the Services, whether
                                                directly or through third parties (such as search engines), without the consent of PikR;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Disclose information that you do not have the consent to disclose (such as confidential
                                                information of others (including your employer));
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Violate the intellectual property rights of others, including copyrights, patents, trademarks,
                                                trade secrets or other proprietary rights. For example, do not copy or distribute (except
                                                through the available sharing functionality) the posts or other content of others without
                                                their permission, which they may give by posting under a Creative Commons license;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Violate the intellectual property or other rights of PikR, including, without limitation, (i)
                                                copying or distributing our learning videos or other materials or (ii) copying or distributing
                                                our technology, unless it is released under open source licenses; (iii) using the word “PikR”
                                                or our logos in any business name, email, or URL except as provided in the Brand Guidelines;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Post anything that contains software viruses, worms, or any other harmful code;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the
                                                source code for the Services or any related technology that is not open source;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Imply or state that you are affiliated with or endorsed by PikR without our express consent
                                                (e.g., representing yourself as an accredited PikR trainer);
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or
                                                access to the same, without PikR’s consent;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Deep-link to our Services for any purpose other than to promote your profile on our
                                                Services, without PikR’s consent;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Use bots or other automated methods to access the Services, add or download contacts,
                                                send or redirect messages;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Monitor the Services’ availability, performance or functionality for any competitive purpose;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the
                                                Services;
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Overlay or otherwise modify the Services or their appearance (such as by inserting elements
                                                into the Services or removing, covering, or obscuring an advertisement included on the
                                                Services);
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam,
                                                denial of service attack, viruses, gaming algorithms); and/or
                                            </Typography>
                                            <Typography variant='body2' component='li' gutterBottom>
                                                Violate the any additional terms concerning a specific Service that are provided when you
                                                sign up for or start using such Service.
                                            </Typography>
                                        </ol>
                                    </Typography>
                                </ol>
                                
                            
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>Complaints
                            <Typography variant='body2' component='' gutterBottom>
                                We respect the intellectual property rights of others. We require that information posted by
                                Members be accurate and not in violation of the intellectual property rights or other rights of third
                                parties. We provide a policy and process for complaints concerning content posted by our Members.
                            </Typography>
                        </Typography>
                        <Typography variant='body2' component='li' gutterBottom>How To Contact Us
                            <Typography variant='body2' component='' gutterBottom>
                                For all inquiries, you may contact us online .
                            </Typography>
                        </Typography>
                    </ol>
                </Paper>
            </div>
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        margin: '3% auto 5%',
        width: '65%',
    },
    title: {
        textAlign: 'start',
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    page: {
        padding: theme.spacing(5, 8),
        color: theme.palette.text.primary,
        '& ol': {
            paddingInlineStart: '1rem',
            paddingInlineEnd: '1rem'
        },
        '& ol li': {
            paddingInlineStart: '1rem'
        },
        '& ol li::marker': {
            fontWeight: 500,
        },
        
    },
}));
