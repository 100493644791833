  import React, { useState } from 'react';
  import { Link } from 'react-router-dom';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
//import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
// Material-ui Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
//import ExtensionIcon from '@material-ui/icons/Extension';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import MoreIcon from '@material-ui/icons/MoreVert';

// Custom Imports
import { AuthUserContext } from '../Session';
import { ReactComponent as Logo } from '../../assets/pikr-logo.svg'
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
//import * as ROLES from '../../constants/roles';
//import { Typography } from '@material-ui/core';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => {
  const classes = useStyles();
  const [accountMenu_anchorEl, setAccountMenuAnchorEl] = useState(false);
  const [mobileMenu_anchorEl, setMobileMenu_anchorEl] = React.useState(null);
  const account_open = Boolean(accountMenu_anchorEl);
  const mobileMenu_open = Boolean(mobileMenu_anchorEl);

  const handleAccountMenu = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };
  const handleMobileMenueOPEN = (event) => {
    setMobileMenu_anchorEl(event.target);
  }
  const handleMobileMenueCLOSE = () => {
    setMobileMenu_anchorEl(null);
  }
  const handleClose = () => {
    setAccountMenuAnchorEl(null);
    handleMobileMenueCLOSE();
  };

  const accountMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={accountMenu_anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={account_open}
      onClose={handleClose} >

      <MenuItem
        onClick={handleClose}
        component={Link}
        to={ROUTES.ACCOUNT} >
        My Account
      </MenuItem>
      {/* <MenuItem
        onClick={handleClose}
        component={Link}
        to={{ pathname: 'https://chrome.google.com/webstore/detail/pikr-job-seekers/ofimoljbdhcgnijhacdclanbojdgjefn' }}
        target='_blank' >
        Extension
      </MenuItem> */}
      <Divider />
      <SignOutButton onClick={handleClose} />

    </Menu>
  );
  const mobileMenu = (
    <Menu
      id='mobile-menu'
      anchorEl={mobileMenu_anchorEl}
      onClose={handleMobileMenueCLOSE}
      open={mobileMenu_open}
    >
      <MenuItem
        onClick={handleMobileMenueCLOSE}
        component={Link}
        to={ROUTES.HOME}
      >
        <IconButton disableRipple>
          <HomeIcon />
        </IconButton>
        <p>Dashboard</p>
      </MenuItem>
      <MenuItem
        onClick={handleMobileMenueCLOSE}
        component={Link}
        to={ROUTES.SAVED}
      >
        <IconButton disableRipple>
          <FavoriteIcon />
        </IconButton>
        <p>Saved</p>
      </MenuItem>
      <MenuItem
        id='mobile_account_button'
        aria-controls="menu-appbar"
        onClick={handleAccountMenu}
      >
        <IconButton disableRipple>
          <AccountCircle />
        </IconButton>
        <p>Account</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar className={classes.bar_scrolled} >
      <Toolbar className={classes.toolbar}>
        <Link to={ROUTES.LANDING} >
          <Logo className={classes.title_scrolled} />
        </Link>

        <div className={classes.links_root}>
          <Button
            color="inherit"
            className={classes.buttonLinks}
            component={Link}
            to={ROUTES.HOME}>
            Dashboard
          </Button>
          <Button
            disableRipple
            color="inherit"
            className={classes.buttonLinks}
            component={Link}
            to={ROUTES.SAVED}>
            Saved
          </Button>
          
          {/* {!!authUser.roles[ROLES.ADMIN] && (
            <Button disableRipple color="inherit" className={classes.buttonLinks} component={Link} to={ROUTES.ADMIN}>Admin</Button>
          )} */}

          <IconButton
            id='account_button'
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenu}
            className={classes.accountButton}
          >
            <AccountCircle fontSize="large" />
          </IconButton>

        </div>
        <div className={classes.mobile_links_root}>
          <IconButton
            id='mobile_menu_button'
            aria-controls='mobile-menu'
            aria-haspopup="true"
            onClick={handleMobileMenueOPEN}
          >
            <MoreIcon />
          </IconButton>
        </div>
        {mobileMenu}
        {accountMenu}
      </Toolbar>
    </AppBar>
  );
}

const NavigationNonAuth = () => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 15,
  });

  return (
    <AppBar
      className={trigger ? (classes.bar_scrolled) : (classes.bar)}
    >
      <Toolbar className={classes.toolbar}>

        <Link to={ROUTES.LANDING} >
          {Logo ?
            <Logo className={trigger ? (classes.title_scrolled) : (classes.title)} />
            : ('pikr')}
        </Link>
       
        <div>
          <Button
            disableRipple
            disableElevation
            className={classes.buttonLinks}
            component={Link}
            to={ROUTES.SIGN_IN}
            size='large'
          >
            Login
          </Button>
          <Button
            disableRipple
            disableElevation
            variant="contained"
            color='primary'
            className={classes.buttonLinks}
            component={Link}
            to={ROUTES.SIGN_UP}
            size={trigger ? ('') : ('large')}
          >
            Get Started
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  bar: {
    transition: 'background-color 0.5s',
    backgroundColor: 'transparent',
  },
  bar_scrolled: {
    transition: 'background-color 0.5s',
    backgroundColor: 'white',
  },
  toolbar: {
    margin: theme.spacing(0, 6),
    justifyContent: 'space-between',
    '& >a': {
      display: 'inline-flex',
      margin: theme.spacing(1.5, 0),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  title: {
    maxHeight: '3.5rem',
    flexGrow: 0,
    textDecoration: 'none',
    transitionDuration: '0.5s',
    transitionProperty: 'max-height, max-width',
    transitionTimingFunction: 'ease-out',

    [theme.breakpoints.between('xs', 'md')]: {
      maxHeight: '4rem',
    },
  },
  title_scrolled: {
    maxHeight: '3.5rem',
    flexGrow: 0,
    textDecoration: 'none',
    transitionDuration: '0.5s',
    transitionProperty: 'max-height, max-width',
    transitionTimingFunction: 'ease-out'
  },
  links_root: {
    // flexGrow: 1,
    // display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobile_links_root: {
    display: 'none',

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  buttonLinks: {
    marginLeft: theme.spacing(1),
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1),
    },
  },
  accountButton: {
    marginLeft: theme.spacing(1),
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
  },
  chipLink: {
    // display: 'inline-block',
    // alignSelf: 'center',
    // height: '2rem',
    // width: '13rem',
    textDecoration: "none",
    marginRight: theme.spacing(2),
    '&:hover': {
      cursor: "pointer"
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

export default Navigation;


