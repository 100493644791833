import React from 'react';
// Materil-UI Components
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Custom Components
//import { AddLocationForm } from "./locationDetails";
import {UpdatePersonalDetails} from "./personalDetails";
import {SocialLinksForm} from "./socialPagesDetails";
import { Typography } from '@material-ui/core';
import {UplaodCVForm} from '../FileUpload/index';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function EditDetailsTabs(props) {
    const [value, setValue] = React.useState(0);
    const docURL = ('user_doc' in props.authUser ? `https://docs.google.com/viewer?url=${encodeURIComponent(props.authUser.user_doc.docURL)}&embedded=true` : null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="fullWidth"
            >
                <Tab label="Personal Details" {...a11yProps(0)} />
                <Tab label="View Current CV" {...a11yProps(1)} />
                <Tab label="Social Pages" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <UpdatePersonalDetails authuser={props.authUser} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {docURL === null ? (
                    <div justify="center" display="flex">
                        <Typography>
                            No Doc uploaded
                        </Typography>
                        <UplaodCVForm authuser={props.authUser}></UplaodCVForm>        
                    </div>
                    ): (
                    <div>
                        <iframe title='cv_viewer' src={docURL} width="100%" height="1080px" frameBorder="0" />
                    </div>
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SocialLinksForm authuser={props.authUser} />
            </TabPanel>
        </div>
    );
}

export { EditDetailsTabs };