/*global chrome*/
import firebase from 'firebase/app';
require('firebase/database');
require('firebase/storage');
require('firebase/firebase-functions');
require('firebase/firestore');
require('firebase/analytics');
require('firebase/auth');

const config = { 
  apiKey: "AIzaSyD9sXIUrsZKH7u1opTMfi8bqfAaDjAGA-E", 
  authDomain: "pikr-prod.firebaseapp.com", 
  databaseURL: "https://pikr-prod.firebaseio.com", 
  projectId: "pikr-prod", 
  storageBucket: "pikr-prod.appspot.com", 
  messagingSenderId: "1001429685722", 
  appId: "1:1001429685722:web:2e01fbabfe8ead48b99ce3", 
  measurementId: "G-HTTF7YVYTG" // New MID
};
const extIDs = {
  dev: 'hccmdfbpgnigopajdibmcpdifglnhgjn',
  prod: 'ofimoljbdhcgnijhacdclanbojdgjefn'
}

let userAgentString = navigator.userAgent;

class Firebase {
  constructor() {
    firebase.initializeApp(config);

    /* Helper */
    this.analytics = firebase.analytics();
    this.serverValue = firebase.database.ServerValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;
    this.functions = firebase.functions();

    /* Firebase APIs */
    this.auth = firebase.auth();
    this.db = firebase.database();
    this.storage = firebase.storage();
    this.fireStore = firebase.firestore();

    /* Social Sign In Method Provider */
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    // this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);
  //  Facebook Provider API
  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithLinkedin = (token) =>
    this.auth.signInWithCustomToken(token);

  /*  Twitter Prover API
    doSignInWithTwitter = () =>
      this.auth.signInWithPopup(this.twitterProvider);
  */

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  /*    Email Verification API to Firebase
    doSendEmailVerification = () =>
      this.auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      });
  */
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Firebase Authentication Listener *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        // authUser._lat = user's token
        // Send user token to Extension for Authentication
        if (userAgentString.indexOf("Chrome") > -1) {
          this.doExtensionAuth(authUser._lat);
        }

        //Get user's RT-DB details, else empty profile
        this.user(authUser.uid)
          .on('value', snapshot => {
            var dbUser = snapshot.val();

            // default empty profile
            if (!dbUser || !dbUser.roles) {
              // Create DB Context
              dbUser = {
                email: authUser.email,
                username: authUser.displayName || '',
                location: {
                  localArea: "",
                  country: '',
                  region: ''
                },
                altLocation: {
                  localArea: "",
                  country: '',
                  region: ''
                },
                socialPages: {
                  facebook: "",
                  github: "",
                  linkedin: "",
                  personal: ""
                },
                Skills: [],
                sector: "",
                roles: {
                  CANDIDATE: true,
                },
              }
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              /*  emailVerified: authUser.emailVerified, */
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        this.doExtensionAuth(null);
        fallback();
      }
    });

  doExtensionAuth = (token) => {
    if (token) {
      console.log(`Sending to signIn Extension`);
      if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
        chrome.runtime.sendMessage(
          extIDs.prod,
          { action: "sign-in", data: token }
        );
      }
    } else {
      console.log(`Sending to signOut Extension`);
      if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
        chrome.runtime.sendMessage(
          extIDs.prod,
          { action: "sign-out" }
        );
      }
    }
  }
  // *** User DB API ***
  user = uid => this.db.ref(`users_details/${uid}`);

  users = () => this.db.ref('users_details');

  // *** User Storage API ***
  userStorage = uid => this.storage.ref(`user_files/${uid}`);

  // *** User's Search Paramarters ref *** 
  userSearchParam = uid => this.fireStore.collection('searchQueries').doc(uid)

  // // *** Message API ***
  // message = uid => this.db.ref(`messages/${uid}`);
  // messages = () => this.db.ref('messages');
}

export default Firebase;