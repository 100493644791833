import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
//Material-ui
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
//Material-ui Icons

// Custom Imports
import { withFirebase } from '../Firebase';
import { UploadAvatar, VariantUplaodCVForm ,UplaodCVForm } from '../FileUpload/index';
import { UpdateCurrentLocation, VariantUpdateCurrentLocation } from './locationDetails';

const useStyles = makeStyles((theme) => ({
    saveButton: {
        margin: theme.spacing(1, 0),
        width: '50%',
    },
}));

function UpdatePersonalBase(props) {
    const classes = useStyles();
    const [JobTitle, setJobTitle] = useState(!props.authuser.jobTitle ? '' : (props.authuser.jobTitle));
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event) => {
        setJobTitle(event.target.value)
    }

    function saveDetails() {
        //console.log('saving');
        props.firebase
            .user(props.authuser.uid)
            .update({ jobTitle: JobTitle })
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('jobTitle_updated');
                //////////////////////////////////////
                enqueueSnackbar("Update Successful", {
                    variant: 'success',
                });
                console.log('saved');
            });
    }
    function cancel() {
        console.log('cancel');
        setJobTitle(!props.authuser.jobTitle ? ('') : (props.authuser.jobTitle));
    }

    return (
        <div>
            <Grid container spacing={2} alignItems='stretch' justify="center">
                <Grid item sm={6} spacing={3} container alignItems='center' justify="center" >
                    <Grid item >
                        <TextField
                            id="jobTitle"
                            variant="filled"
                            margin="dense"
                            fullWidth
                            label="Job Title"
                            color='primary'
                            value={JobTitle}
                            onChange={handleChange}
                            helperText='Enter your Job Title for more relevant Piks'
                        />
                        {"jobTitle" in props.authuser && props.authuser.jobTitle !== ""?
                        <Button
                            className={classes.saveButton}
                            variant='contained'
                            onClick={saveDetails}>
                            Saved
                        </Button>
                        :
                        <Button
                            className={classes.saveButton}
                            variant='contained'
                            color='primary'
                            onClick={saveDetails}>
                            Save
                        </Button>
                        }
                    </Grid>

                    <Grid item >
                        {props.authuser.location.country === ""? 
                            <UpdateCurrentLocation authuser={props.authuser} /> 
                            : 
                            <VariantUpdateCurrentLocation authuser={props.authuser} />
                        }
                    </Grid>

                </Grid>

                <Grid item container sm={6} spacing={3} direction='column' justify="center" alignItems='center'>
                {"user_doc" in props.authuser? 
                    <Typography align='center' variant='subtitle2' gutterBottom>
                        You can update your most recent CV here
                    </Typography>:
                    <Typography align='center' variant='subtitle2' gutterBottom>
                        Upload your most recent CV to start receiving your Piks.
                    </Typography>
                }
                    <Grid item >
                        {"user_doc" in props.authuser? 
                        <VariantUplaodCVForm authuser={props.authuser} />
                        :
                        <UplaodCVForm authuser={props.authuser} />
                        }
                    </Grid>
                    <Grid item  >
                        <UploadAvatar authuser={props.authuser} />
                    </Grid>

                </Grid>

            </Grid>
        </div>
    )

}

const UpdatePersonalDetails = withFirebase(UpdatePersonalBase);

export { UpdatePersonalDetails }
