import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Material-UI Icons
import FavoriteIcon from '@material-ui/icons/Favorite';
// Custom Components
import { withFirebase } from '../Firebase';
import { StoredPikS } from "./PikS";
// import { sampleData } from "./sampleData";

function SavedPikS(props) {
    let display;
    const ref = props.firebase.db.ref(`saved_piks/${props.uid}`);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [IsLoading, setIsLoading] = useState(true);
    const [SavedPiks, setSavedPiks] = useState([]);

    function deletePik(key) {
        // Analytics
        props.firebase.analytics.logEvent('delete_pik');
        //////////////////////////////
        ref.child(key).remove();
        enqueueSnackbar("Deleted Pik", {
            variant: 'info',
        });
    }

    function copyAll() {
        // Analytics
        props.firebase.analytics.logEvent('copy_all');
        //////////////////////////////
        // console.log('Copy All');
        var copyText = '';
        SavedPiks.forEach(item => {
            copyText += item.link + '\n'
        })
        navigator.clipboard.writeText(copyText);
        enqueueSnackbar("All Links Copied!", {
            variant: 'info',
        });
    }

    function deleteAll() {
        const updateList = {};
        SavedPiks.map(item => {
            return updateList[item.id] = null;
        });
        ref.update(updateList)
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('delete_all');
                //////////////////////////////
                enqueueSnackbar("Deleted all PikS", {
                    variant: 'info',
                });
            });
    }

    useEffect(() => {
        const listener = ref.on('value', snap => {
            const PiksList = [];
            snap.forEach(childSnap => {
                const key = childSnap.key;
                const data = childSnap.val();
                PiksList.push({ id: key, ...data });
            });
            setSavedPiks(PiksList);
            setIsLoading(false);
        });
        return () => ref.off('value', listener);
    }, [ref]);

    if (SavedPiks.length > 0) {
        display = (
            <StoredPikS
                analytics={props.firebase.analytics}
                storedPiks={SavedPiks}
                deletePik={deletePik}
            />
        )
    } else {
        display = <NoPiksCard />
    }

    return (
        <div className={classes.section_root} >
            <div className={classes.section_heading} >
                <Typography variant='h4' component='h2' >
                    Saved PikS
                </Typography>
                {!IsLoading && SavedPiks.length > 0 ? (
                    <div className={classes.action_bar}>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={copyAll}
                        > Copy All </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={deleteAll}
                        > Delete All </Button>
                    </div>
                ) : (null)}
            </div>

            {IsLoading ? (
                <div className={classes.loading} >
                    <Typography gutterBottom >LOADING...</Typography>
                    <CircularProgress color='secondary' />
                </div>
            ) : (
                <div >
                    {display}
                </div>
            )}
        </div>
    )
}

function NoPiksCard() {
    const classes = useStyles();

    return (
        <Card className={classes.noPiksCard_root}>
            <CardContent align='center'>
                <Typography variant='h5' component='h4' gutterBottom >
                    You don't have any piks saved.
                </Typography>
                <Typography variant='body2' >
                    You can use the {<FavoriteIcon fontSize='small' color='secondary' />} button
                    on a Pik you would like to save.
                </Typography>
            </CardContent>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    section_root: {
        minHeight: '70vh',
        maxWidth: '75vw',
        margin: 'auto',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            maxWidth: '100%',
        },
    },
    section_heading: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        '& >h2': {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: theme.palette.grey.main,
            alignSelf: 'center',
        },
    },
    action_bar: {
        display: 'flex',
        // justifyContent: 'flex-end',
        margin: theme.spacing(1, 0),
        '& > button': {
            marginLeft: theme.spacing(1.5),
        }
    },
    loading: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noPiksCard_root: {
        maxWidth: '40%',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '90%',
        },
    },
}));

export default withFirebase(SavedPikS);