import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// Material-UI Components
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead.
`;

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onClick = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Analytics
        this.props.firebase.analytics.logEvent('login', {
          method: 'Google'
        });
        //////////////////////////////
        // Create a user in your Firebase Realtime Database too
        this.setState({ error: null });
        this.props.history.push(ROUTES.ACCOUNT);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    var { classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.button_root}>
        <Button variant='contained' className={classes.button} onClick={this.onClick}>Continue with Google</Button>

        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  bottom: {
    color: 'lightGrey',
  },
  top: {
    color: '#7A43BC',
    animationDuration: '800ms',
    position: 'absolute',
  },
  circle: {
    strokeLinecap: 'round',
  },
  button_root: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(1),
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,//'#db3236',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }

  }
});

const SignInGoogle = compose(
  withStyles(useStyles),
  withRouter,
  withFirebase,
)(SignInGoogleBase);

export default SignInGoogle;