import React, { useState } from 'react'
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//Custom Components
// import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: 'inherit',
        minHeight: '30vh',
        padding: theme.spacing(1),
    },
    cardAction: {
        justifyContent: 'center',
    },
    deleteBtn: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.text.primary,
        }
    },
    confirmDeleteBtn: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    }
}));

function DeleteAccountCard(props) {
    const [Confirm, setConfirm] = useState(false);
    const [Error, setError] = useState(null);
    const classes = useStyles();

    const confirmDelete = () => {
        setConfirm(true);
    }

    const cancelDelete = () => {
        setConfirm(false);
    }

    const deleteAccount = () => {
        console.log('Delete Account');
        return props.firebase.auth.currentUser
            .delete()
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('account_deleted');
                //////////////////////////////////////
                console.log('user deleted');
                //  props.history.push(ROUTES.LANDING);
            })
            .catch(err => {
                console.error("Initial ERROR message:" + err);
                setError({message: "Please sign OUT and IN again to confirm you want to delete your account."});
                setConfirm(false);
                console.error(err);
            })
    }

    return (
        <Card className={classes.card} >
            <CardContent align='center'>
                <Typography variant='h4' gutterBottom >Delete Account</Typography>
                <Typography variant='body2'  >
                    When clicking the button below, we will delete your account and any data we have stored under your account.
                </Typography>


            </CardContent>
            <CardActions className={classes.cardAction} >
                <Grid container direction='column' align='center' justify='center'>
                    <Grid item>
                        {Confirm ? (<Typography variant='overline' >Are you sure?</Typography>) : (null)}
                    </Grid>
                    <Grid item>
                        {Error != null ? (<Typography variant='body2' color="secondary" >{Error.message}</Typography>) : (
                            !Confirm ? (
                                <Button className={classes.deleteBtn} variant='contained' onClick={confirmDelete}>Delete Account</Button>
                            ) : (
                                    <ButtonGroup variant='contained' >
                                        <Button variant='outlined' className={classes.confirmDeleteBtn} onClick={deleteAccount} >Yes</Button>
                                        <Button onClick={cancelDelete} >No</Button>
                                    </ButtonGroup>
                                )
                        ) }
                    </Grid>
                </Grid>

            </CardActions>
        </Card>
    )
}

export default withFirebase(DeleteAccountCard)