import React, { Component } from 'react';
import { compose } from 'recompose';
// Material-UI Components
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
  /*
  {
    id: 'facebook.com',
    provider: 'facebookProvider',
  },
  {
    id: 'twitter.com',
    provider: 'twitterProvider',
  },
  */
];

const styles = (theme) => ({
  card: {
      minHeight: '40vh'
  }
});

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password,
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };
  render() {
    const { classes } = this.props;
    const { activeSignInMethods, error } = this.state;

    return (
      <Card className={classes.card} >
        <CardContent >
          <Typography variant='h4' align='center' >Sign In Methods:</Typography>
          <List>
            {SIGN_IN_METHODS.map(signInMethod => {
              const onlyOneLeft = activeSignInMethods.length === 1;
              const isEnabled = activeSignInMethods.includes(
                signInMethod.id,
              );

              return (
                <ListItem key={signInMethod.id}>
                  {signInMethod.id === 'password' ? (
                    <DefaultLoginToggle
                      onlyOneLeft={onlyOneLeft}
                      isEnabled={isEnabled}
                      signInMethod={signInMethod}
                      onLink={this.onDefaultLoginLink}
                      onUnlink={this.onUnlink}
                    />
                  ) : (
                      <SocialLoginToggle
                        onlyOneLeft={onlyOneLeft}
                        isEnabled={isEnabled}
                        signInMethod={signInMethod}
                        onLink={this.onSocialLoginLink}
                        onUnlink={this.onUnlink}
                      />
                    )}
                </ListItem>
              );
            })}
          </List>
          {error && error.message}
        </CardContent>
      </Card>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <Button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </Button>
  ) : (
      <Button
        type="button"
        onClick={() => onLink(signInMethod.provider)}
      >
        Link {signInMethod.id}
      </Button>
    );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? (
      <Button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id}
      </Button>
    ) : (
        <form onSubmit={this.onSubmit}>
          <TextField
            size="small"
            variant="outlined"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
            autoComplete= "new-password"
          />
          <TextField
            size="small"
            variant="outlined"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
            autoComplete= "new-password"
          />

          <Button variant="contained" disabled={isInvalid} type="submit">
            Link {signInMethod.id}
          </Button>
        </form>
      );
  }
}

const LoginManagement = compose(
  withFirebase,
  withStyles(styles)
)(LoginManagementBase)

export default LoginManagement;

