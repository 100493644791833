import React, { Component } from 'react';

import { withFirebase } from '../../Firebase';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardActions, CardContent, Typography } from '@material-ui/core';


class UserItem extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const moveSavedData = this.props.firebase.functions.httpsCallable('moveSavedDataToFirestore');
    const classes = useStyles();
    
    return (
      <div>
        <Card className={classes.card} >
          <CardContent >
              <Typography variant='h4' align='center' gutterBottom >Move Piks Data</Typography>
              <Typography variant='body2' align='center' >
                  You can delete the CV you have uploaded.
              </Typography>
          </CardContent>
          <CardActions className={classes.cardAction} >
              <Button className={classes.deleteBtn} variant='contained' size="large" onClick={moveSavedData()} >Delete CV</Button>
          </CardActions>
        </Card>
      </div>
      );
  }
}
function moveSavedDataToFirestore(props) {
  
  
  
}

export default withFirebase(moveSavedDataToFirestore);

const useStyles = makeStyles((theme) => ({
  card: {
    height:"100%",
    minHeight: '30vh'
},
cardAction: {
    justifyContent: 'center',
},
noDoc_text: {
    color: 'secondary'
},
deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.primary,
    },
},
}))

