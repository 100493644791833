import firebase from "firebase";
require('firebase/analytics');

function recordEvent(data) {
    const analytics = firebase.analytics()
    analytics.logEvent(
        `${data.eventName}`,
        {
            event_name: data.event_name,
            date_time: Date.now(),
            page: data.pageName
        }
    )
    console.log('Event Logged');
    return;
};

export default recordEvent; 