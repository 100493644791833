import React, { Component } from 'react';
import FileUploader from "react-firebase-file-uploader";
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
// Material-UI imports
import Button from '@material-ui/core/Button';
// Custom imports
import { withFirebase } from '../Firebase';

const cv_success_message = "Successfully uploaded your CV.\nNote* it may take a minute to process";

class UploadCVFormBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            progress: 0,
            fileURL: "",
            fileName: ""
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        // Analytics
        this.props.firebase.analytics.logEvent('upload_cv');
        //////////////////////////////////

        // Add Firebase Storage Methods to get the fileURL 
        // then Save to user_details
        this.props.firebase
            .userStorage(this.props.authuser.uid)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                const user_doc = {
                    docURL: url,
                    docName: filename,
                    lastUpdated: Date.now(),
                    processing: true,
                }
                return this.props.firebase.user(this.props.authuser.uid)
                    .update({
                        user_doc: user_doc
                    })
                    .catch(error => console.log(error));

            })
            .then(() => {
                this.props.enqueueSnackbar(cv_success_message, {
                    variant: 'success',
                });
                this.setState({ fileName: filename, progress: 100, isUploading: false });
            })
            .catch(error => console.log(error));
    };

    render() {
        return (
            <Button component='label' variant="contained" color='primary' disableElevation>
                {this.state.isUploading ? (<p>Progress: {this.state.progress}%</p>)
                    : "Upload CV"
                }
                <FileUploader
                    hidden
                    accept=".pdf, .doc, .docx"
                    name="cv_doc"
                    filename={file => this.props.authuser.username + "_cv"}
                    storageRef={this.props.firebase.userStorage(this.props.authuser.uid)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                />
            </Button>
        )
    }

}

class VariantUploadCVFormBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            progress: 0,
            fileURL: "",
            fileName: ""
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        // Analytics
        this.props.firebase.analytics.logEvent('upload_cv');
        //////////////////////////////////

        // Add Firebase Storage Methods to get the fileURL 
        // then Save to user_details
        this.props.firebase
            .userStorage(this.props.authuser.uid)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                const user_doc = {
                    docURL: url,
                    docName: filename,
                    lastUpdated: Date.now(),
                    processing: true,
                }
                return this.props.firebase.user(this.props.authuser.uid)
                    .update({
                        user_doc: user_doc
                    })
                    .catch(error => console.log(error));

            })
            .then(() => {
                this.props.enqueueSnackbar(cv_success_message, {
                    variant: 'success',
                });
                this.setState({ fileName: filename, progress: 100, isUploading: false });
            })
            .catch(error => console.log(error));
    };

    render() {
        return (
            <Button component='label' variant="contained" >
                {this.state.isUploading ? (<p>Progress: {this.state.progress}%</p>)
                    : "user_doc" in this.props.authuser? 'Saved' : 'Upload CV'
                }
                <FileUploader
                    hidden
                    accept=".pdf, .doc, .docx"
                    name="cv_doc"
                    filename={file => this.props.authuser.username + "_cv"}
                    storageRef={this.props.firebase.userStorage(this.props.authuser.uid)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                />
            </Button>
        )
    }

}

class UploadAvatarBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isUploading: false,
            progress: 0,
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        // Analytics
        this.props.firebase.analytics.logEvent('uploaded_avatar');
        //////////////////////////////////////
        // Add Firebase Storage Methods to get the fileURL
        // then Save to user_details
        this.props.firebase
            .userStorage(this.props.authuser.uid)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                return this.props.firebase
                    .user(this.props.authuser.uid)
                    .update({
                        avatar: url
                    })
                    .catch(err => console.error(err))
            })
            .then(() => {
                this.props.enqueueSnackbar('Avater Upload Sucessful', {
                    variant: 'success',
                });
            })
            .catch(err => console.error(err))
        this.setState({ progress: 100, isUploading: false });
    };

    render() {
        return (
            <form>
                <Button component='label' variant="contained" disableElevation>
                    {this.state.isUploading ? (<p>Progress: {this.state.progress}%</p>)
                        : "Upload Avatar"
                    }
                    <FileUploader
                        hidden
                        accept="image/*"
                        name="userAvatar"
                        filename={file => this.props.authuser.username + "_avatar"}
                        storageRef={this.props.firebase.userStorage(this.props.authuser.uid)}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                    />
                </Button>
            </form>
        )
    }

}

const UplaodCVForm = compose(
    withFirebase,
    withSnackbar
)(UploadCVFormBase)

const VariantUplaodCVForm = compose(
    withFirebase,
    withSnackbar
)(VariantUploadCVFormBase)

const UploadAvatar = compose(
    withFirebase,
    withSnackbar
)(UploadAvatarBase);

export { UplaodCVForm, VariantUplaodCVForm, UploadAvatar };
