import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// Material-UI Icons
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RefreshIcon from '@material-ui/icons/Refresh';

function SearchedPikS(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [PikS, setPikS] = useState(props.latestPiks)
    const classes = useStyles();

    const OpenPik = (link) => {
        // Analytics
        props.analytics.logEvent('open_link');
        /////////////////////////////////////////////////////
        window.open(link, "_blank")
        //console.log('Open');
    }
    const CopyPik = (link) => {
        // Analytics
        props.analytics.logEvent('copy_link');
        //////////////////////////////////////
        navigator.clipboard.writeText(link);
        //console.log('Copy');
        enqueueSnackbar("Link Copied", {
            variant: 'info',
        });
    }
    const SavePik = (pik, key) => {
        props.savePik(pik);
        var updatedPiks = PikS.map((item, i) => {
            if (i === key) {
                return { ...item, saved: true }
            } else {
                return { ...item };
            }
        })
        setPikS(updatedPiks);
    }
    useEffect(() => {
        setPikS(props.latestPiks)
        return () => {
            setPikS(null);
        }
    }, [props.latestPiks])

    return (
        <React.Fragment>
            {PikS.map((item, i) => (
                <Grid key={i} item xs={12} sm={6} lg={4}  >
                    <Card raised className={classes.card} >
                        <CardHeader className={classes.card_header}
                            title={
                                <Typography noWrap align='left' variant='subtitle1' component='p'>{item.title}</Typography>
                            } />
                        <CardContent className={classes.card_content} onClick={() => OpenPik(item.link)} >
                            <Typography variant='body2' >
                                {item.snippet}
                            </Typography>
                        </CardContent>

                        <CardActions className={classes.card_actions} >
                            <div>
                                <Button
                                    onClick={() => OpenPik(item.link)} >
                                    open
                                </Button>
                                <Button onClick={() => CopyPik(item.link)} >
                                    copy
                                </Button>
                            </div>

                            <div>
                                {!item.saved ? (
                                    <IconButton color='inherit' onClick={() => SavePik(item, i)} >
                                        <Tooltip title="Save" >
                                            <FavoriteIcon />
                                        </Tooltip>
                                    </IconButton>
                                ) : (
                                    <IconButton className={classes.actions_save} >
                                        <Tooltip title="Saved" >
                                            <FavoriteIcon />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
            <Grid item xs={12} sm={6} lg={4} >
                <Card raised className={classes.card}>
                    <CardContent className={classes.refresh_card}>
                        <IconButton onClick={props.refresh}>
                            <RefreshIcon className={classes.refresh_icon} />
                        </IconButton>
                    </CardContent>
                </Card>
            </Grid>
        </React.Fragment>
    )
}

function StoredPikS(props) {
    const { enqueueSnackbar } = useSnackbar();
    const PikS = props.storedPiks;
    const classes = useStyles();

    const handleOpen = (link) => {
        // Analytics
        props.analytics.logEvent('open_link');
        /////////////////////////////////////////////////////
        window.open(link, "_blank")
        //console.log('Open');
    }
    const handleCopy = (link) => {
        // Analytics
        props.analytics.logEvent('copy_link');
        //////////////////////////////////////
        navigator.clipboard.writeText(link);
        //console.log('Copy');
        enqueueSnackbar("Link Copied", {
            variant: 'info',
        });
    }

    return (
        <React.Fragment >
            <Grid container spacing={2} >
                {PikS.map((item) => (
                    <Grid key={item.id} item xs={12} sm={6} lg={4} >
                        <Card raised className={classes.card} >
                            <CardHeader className={classes.card_header}
                                title={
                                    <Typography noWrap align='left' variant='subtitle1' component='p'>{item.title}</Typography>
                                } />
                            <CardContent className={classes.card_content} onClick={() => handleOpen(item.link)} >
                                <Typography align='left' variant='body1' >
                                    {item.snippet}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.card_actions} >
                                <div>
                                    <Button
                                        onClick={() => handleOpen(item.link)} >
                                        open
                                    </Button>
                                    <Button
                                        onClick={() => handleCopy(item.link)} >
                                        copy
                                    </Button>
                                </div>
                                <div>
                                    <IconButton color='inherit' onClick={() => props.deletePik(item.id)} >
                                        <Tooltip title="Delete" >
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </div>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    )
}

// Custom Stylings
const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: '16rem',
        maxHeight: '16rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    card_link: {
        textDecoration: 'none',
    },
    card_header: {
        padding: theme.spacing(1.5, 3),
        backgroundColor: theme.palette.primary.main, //'#737373',
        color: 'white',
        cursor: 'pointer',
        display: 'block',
        '& div>p': {
            fontWeight: 500,
            fontSize: '',
        }
    },
    card_content: {
        overflowY: 'auto',
        color: 'black',
        cursor: 'pointer',
        margin: theme.spacing(2, 3),
        padding: 0,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        // msOverflowStyle: 'none',
        // scrollbarWidth: 'none',
    },
    card_actions: {
        padding: theme.spacing(0),
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.secondary.main}4d`,
        '&>:last-child': {
            backgroundColor: theme.palette.secondary.main,
            color: '#ffffffb3',
        },
        '&>:first-child': {
            color: 'white',
            marginLeft: theme.spacing(2),
        },
    },
    actions_save: {
        color: theme.palette.error.main,
    },
    refresh_card: {
        height: '100%',
        justifyItems: 'center',
        margin: 'auto',
    },
    refresh_icon: {
        fontSize: theme.spacing(8),
    },
}));

export { SearchedPikS, StoredPikS }
