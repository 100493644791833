import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// Material-UI Components
import ButtonBase from '@material-ui/core/ButtonBase';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// Custom Imports
import BookmarkButton from '../BookmarkButton';
import { withFirebase } from '../Firebase';
//import { PasswordForgetLink } from '../PasswordForget';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                // Analytics
                this.props.firebase.analytics.logEvent('login', {
                    method: 'email_password'
                });
                //////////////////////////////
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;
        const { classes } = this.props;

        const isInvalid = password === '' || email === '';

        return (
            <div className={classes.root}>
                <Typography variant="h4" component="h1">
                    Sign in
                </Typography>
                <form id='signInForm' className={classes.form} onSubmit={this.onSubmit}>
                    <TextField
                        id="email"
                        variant="filled"
                        margin="dense"
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={this.onChange}
                    />
                    <TextField
                        id="password"
                        variant="filled"
                        margin="dense"
                        required
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={this.onChange}
                    />
                    <ButtonBase
                        disabled={isInvalid}
                        type="submit"
                        className={classes.submit}
                    >
                        <BookmarkButton text='Submit' color='primary' disabled={isInvalid} />
                    </ButtonBase>

                    <Typography variant='subtitle2' className={classes.hyperlink}>
                        <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
                    </Typography>

                </form>
                {error && <Typography variant='subtitle2' color='error' align='center' gutterBottom>{error.message}</Typography>}
            </div>
        );
    }
}

const useStyles = theme => ({
    root: {
        padding: theme.spacing(4, 2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '75%',
        width: '95%',
    },
    form: {
        width: '70%',
        margin: theme.spacing(1, 0),
        '& >div': {
            position: 'sticky',
            margin: theme.spacing(1, 0)
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    hyperlink:{
        textAlign: 'center',
        
        '& a':{
            textDecoration: 'none',
            color: theme.palette.secondary.main,
        }
    },
    submit: {
        margin: theme.spacing(2, 0),
        height: '2.5rem',
        width: '80%',
        left: theme.spacing(-8),
        [theme.breakpoints.down('sm')]: {
            width: '35vw',
            left: '-15%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '50vw',
            left: '-5%',
        },
    },
});

const SignInForm = compose(
    withStyles(useStyles),
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignInForm;