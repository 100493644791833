
export default function GetCurrentLocation(props) {
  console.log("Getting Location.");
  // Invoke Back-End Function to get the user's Location
  const writeLocationData = props.firebase.functions.httpsCallable('writeLocationData');
  return new Promise(function(resolve, reject) {
    //This retrieves the latitude and longitude then sends it to be converted to country 
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(async function (position) {
        const lat = position.coords.latitude.toString();
        const lng = position.coords.longitude.toString();
        console.log(`lat lng sent`)
        await writeLocationData({'latitude': lat, 'longitude': lng}).then(res => {
          console.log("Got Location");
          console.log("Response: " + res)
          resolve(res);
        });
      });
    } else {
      console.log("Faled to find location");
      reject('failed');
    }
    
  });
};