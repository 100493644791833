import React from 'react';
// Material-UI Components
import MenuItem from '@material-ui/core/MenuItem';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => {
  
  const signOut = () => {
    firebase.doSignOut().then(() => firebase.analytics.logEvent('sign_out'));
  }

  return (
    <MenuItem onClick={signOut}>
      Sign Out
    </MenuItem>
  )
};

export default withFirebase(SignOutButton);
