import React from 'react';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Custom Components
import { ReactComponent as ArrowEdge } from '../../assets/arrow-edge-btn.svg';

export default function BookmarkButton({ text, color, center, disabled }) {
    const classes = useStyles({ center, color, disabled });

    return (
        <div className={classes.button_root}>
            <ArrowEdge className={classes.button_img} />
            <Typography
                component='span'
                className={classes.button_text} >
                {text}
            </Typography>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    button_root: {
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
        width: 'inherit',
        cursor: 'pointer',
        pointerEvents: props => props.disabled ? ('none') : (''),
        '&:hover #arrow-btn': {
            fill: props => props.color ? (theme.palette[props.color].light) : (''),
        },
        '&:hover span': {
            backgroundColor: props => props.color ? (theme.palette[props.color].light) : ('')
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    button_text: {
        display: 'inline-flex',
        justifyContent: props => props.center ? ('center') : ('flex-end'),
        alignItems: 'center',
        backgroundColor: props => props.color && !props.disabled ? (theme.palette[props.color].main) : (theme.palette.grey[500]),
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        border: 'none',
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        color: theme.palette.common.white,
        paddingRight: theme.spacing(2),
    },
    button_img: {
        position: 'relative',
        left: '2px',
        height: '100%',
        width: '7rem',
        '& #arrow-btn': {
            fill: props => props.color && !props.disabled ? (theme.palette[props.color].main) : (theme.palette.grey[500]),
        },
    },
}))