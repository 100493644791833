import React from 'react';
import { SnackbarProvider } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  success: { backgroundColor: '#85BC43 !important'  },
  error: { backgroundColor: '#BC4348 !important' },
  warning: { backgroundColor: 'orange' },
  info: { backgroundColor: '#7A43BC !important' },
})

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => {
  const classes = useStyles();
  return (
    <FirebaseContext.Consumer>
      {firebase => (
        <SnackbarProvider
          maxSnack={3}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
        >
          <Component {...props} firebase={firebase} />
        </SnackbarProvider>

      )}
    </FirebaseContext.Consumer>
  );
}

export default FirebaseContext;
