import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let Theme = createMuiTheme({
    palette: {
        primary: {
            main: '#02B4A7',
            light: '#67D2CA',
        },
        secondary: {
            main: '#5958A5',
            light: '#ABABD2',
        },
        error: {
            main: '#EE4B51'
        },
        success: {
            main: '#A1DD30'
        },
        accent: {
            main: '#F7F115'
        },
        grey: {
            dark: '#575757',
            main: '#898989',
            light: '#cccccc',
        },
        text: {
            primary: '#575757',
            secondary: '#aaaa',
        },
    },
    typography: {
        fontSize: 14,
        lineHeight: 1.5,
        fontFamily: [
            'Rubik',
            'Open Sans',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        sectionTitle: {
            color: '#ffffff66',
            fontSize: "16px",
            fontWeight: 500,
            textTransform: 'uppercase',
        }
    },
    props: {
        MuiPaper: {
            elevation: 0,
        },
        MuiFilledInput: {
            disableUnderline: true,
        },
        MuiButton: {
            disableElevation: true,
            disableFocusRipple: true,
            disableRipple: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    shape: {
        borderRadius: 6
    },
    spacing: factor => `${0.5 * factor}rem`,
});

Theme.overrides = {
    MuiAppBar: {
        root: {
            boxShadow: 'none',
        },
        colorPrimary: {
            backgroundColor: 'transparent',
            color: 'black',
        },
    },
    MuiAccordion: {
        root: {
            '&$expanded': {
                margin: '0 0 1rem',
            },
            '&:before': {
                height: 0,
            },
            '&:last-child': {
            },
            backgroundColor: 'transparent',
            padding: '0',
        },
    },
    MuiAccordionDetails: {
        root: {
            backgroundColor: '#B2B2B275',
            borderRadius: '5px',
            padding: '1rem',
            margin: '0'
        }
    },
    MuiAccordionSummary: {
        root: {
            padding: 0,
        },
        expandIcon: {
            transition: Theme.transitions.create('transform', {duration : Theme.transitions.duration.standard}),
        },
    },
    MuiButton: {
        root: {
            boxShadow: 'none',
            transition: 'all 0.5s',
            minWidth: 0,
            color: 'inherit',
        },
        text: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        containedPrimary: {
            color: 'white',
            '&:hover': {
                backgroundColor: Theme.palette.primary.light
            },
        },
        containedSecondary: {
            '&:hover': {
                backgroundColor: Theme.palette.secondary.light
            },
        },
        sizeLarge: {
            fontSize: '1rem',
            padding: '0.5rem 1.5rem',
            transition: 'all 0.5s',
            [Theme.breakpoints.between('xs', 'sm')]: {
                padding: '0.5rem 0.75rem',
                fontSize: '0.95rem',
            },
        }
    },
    MuiCard: {
        root: {
            boxShadow: 'none'
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: '#ffffff35'
        },

    },
    MuiTypography: {
        gutterBottom: {
            marginBottom: 18,
        },
    },
    MuiFilledInput: {
        root: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        }
    },
}

// Responsive utilities
Theme = responsiveFontSizes(Theme);

export default Theme;