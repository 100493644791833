import React from 'react';
import { Link } from 'react-router-dom';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Custom Components
// import { ReactComponent as ExtensionImg } from '../assets/extension_v6.min.svg';
// import  ExtensionImg from '../assets/extension_v6.min.svg';
//import  ExtensionImg from '../assets/extension_v6.svg';
import { ReactComponent as LandingImage } from '../assets/banner-img.svg';
//import { ReactComponent as Logo } from '../assets/pikr-logo.svg';
import BookmarkButton from '../components/BookmarkButton';
import * as ROUTES from '../constants/routes';

import { sites } from '../constants/searched-sites';
import recordEvent from '../components/Firebase/eventListener'


export default function LandingPage() {
    const pageName = 'LandingPage';
    const classes = useStyles();
    return (
        <main className='page-root-landing'>
            {/* Banner Section */}
            <div className={classes.banner}>
                <LandingImage className={classes.banner_img} />
                <div className={classes.banner_caption} >
                    {/* <Typography variant='h4' component='p' color='textSecondary' >WELCOME TO</Typography> */}
                    <Typography variant='h2' component='h1' color='textPrimary'>Job Searching Made Easy!</Typography>
                    {/*<div className={classes.banner_google_logo}>*/}
                    <Typography variant='h4' component='p' color='textSecondary' align='center'  >
                        Pikr helps professionals source relevant
                    </Typography>
                    <Typography variant='h4' component='p' color='textSecondary' align='center'  >
                        jobs faster. And it's free!
                    </Typography>
                        {/* <img
                            src='/cloud_wordmark_color.png'
                            alt='Google Cloud'
                        />
                    </div>*/}

                    <Link className={classes.banner_button} to={ROUTES.SIGN_UP} onClick={recordEvent({
                        eventName: 'click_get_started',
                        event_name: 'GETSTARTED_BUTTON',
                        pageName: pageName
                        })} >
                        <BookmarkButton text='Get Started' color='primary' />
                    </Link>
                </div>
                <Card className={classes.banner_card}>
                    <div className={classes.banner_card_section} >
                        <Typography className={classes.banner_card_heading} variant='h6' component='h2' >
                            Step 1: Sign Up
                        </Typography>
                        <Typography variant='body1' component='p' >
                            {`Create a Pikr Profile using either your email, LinkedIn or Google account`}
                        </Typography >
                    </div>
                    <div className={classes.banner_card_section}>
                        <Typography className={classes.banner_card_heading} variant='h6' component='h2'  >
                            Step 2: Upload CV
                        </Typography>
                        <Typography variant='body1' component='p'>
                            {`Upload your most current CV to you personal Pikr dashboard. `}
                        </Typography>
                    </div>
                    <div className={classes.banner_card_section}>
                        <Typography className={classes.banner_card_heading} variant='h6' component='h2'  >
                            Step 3: Get Searching
                        </Typography>
                        <Typography variant='body1' component='p'>
                            {`Review relevant jobs conveniently listed on one dashboard.`}
                        </Typography>
                    </div>
                </Card>
            </div>
            {/* Searched Sites Section */}
            <div className={classes.sites_searched_section + ' MuiPaper-rounded'}>
                <Typography component='h2' gutterBottom>SITES WE SEARCH</Typography>
                <Grid container justify='space-between'>
                    {sites.map((item, key) => (
                        <Grid item key={key} sm={2} lg={1}>
                            <Avatar src={item} className={classes.site_icon} >
                                LOGO
                            </Avatar>
                        </Grid>
                    ))}
                    <Grid item></Grid>
                </Grid>
            </div>

            {/* Testimonials Section */}


        </main>
    )
}

const useStyles = makeStyles(theme => ({
    '@global':{
        '*::-webkit-scrollbar': {
            display: 'none',
          },
    },
    banner: {
        zIndex: 2,
        height: '88vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'start',
        position: 'relative',

        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('1440.05')]: {
            height: '90vh',
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('414.05')]: {
            height: '165vh', // Make margin 0 @ banner . Make this height 171vh
        },
        [theme.breakpoints.down('390.05')]: {
            height: '158vh',
        },
        [theme.breakpoints.down('375.05')]: {
            height: '180vh',
        },
        [theme.breakpoints.down('360.05')]: {
            height: '230vh',
        },
        [theme.breakpoints.down('280.05')]: {
            height: 'auto',
        },
    },
    banner_caption: {
        margin: '0 0 50px 0',
        position: 'relative',
        alignItems: 'flex-start',
        padding: theme.spacing(4, 4),
        height: '50vh',
        maxWidth: '60%',
        top: '10vh',
        '& >h1': {
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50)/var(--unnamed-line-spacing-54) var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0)',
            color: 'var(--unnamed-color-575757)',
            textAlign: 'left',
            font: 'normal normal bold 50px/54px Rubik',
            letterSpacing: '0px',
            color: '#575757',
            opacity: 1,
        },
        '& >p': {
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0-4)',
            color: 'var(--unnamed-color-cccccc)',
            textAlign: 'left',
            font: 'normal normal bold 20px/27px Rubik',
            letterSpacing: '0.4px',
            color: '#CCCCCC',
            textTransform: 'uppercase',
            opacity: 1,
        },
        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('1680.05')]: {
        },
        [theme.breakpoints.down('1600.05')]:{
        },
        [theme.breakpoints.down('1366.05')]: {
        },
        [theme.breakpoints.down('1280')]:{
            maxWidth: theme.spacing(130),
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '58vw',

        },
        [theme.breakpoints.down('1024.05')]:{
            top: theme.spacing(0),
            maxWidth: '50%',
        }, 
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
            height: '90vh',
        },  
        [theme.breakpoints.down('768.05')]: {
            maxWidth: '85.1vw',
        },
        [theme.breakpoints.down('xs')]: {
            
            justifyContent: 'space-between',
            padding: theme.spacing(2, 2),
        },
        [theme.breakpoints.down('540.05')]: {
            height: '73vh',
        },
        [theme.breakpoints.down('414.05')]: {
            zIndex: 1,
            maxWidth: '85.1vw',
            justifyContent: 'space-between',
            height: '80vh',
        },
        [theme.breakpoints.down('375.05')]: {
            height: '208vw',
            margin: '20px 0 50px 0',
            top: '1vh',
            
            '& >h1': {
                font: 'normal normal bold 30px/34px Rubik'
            },
            '& >p': {
                font: 'normal normal bold 16px/20px Rubik'
            },
        },
        [theme.breakpoints.down('320.05')]: {
            top: '2%',

        },
        [theme.breakpoints.down('280.05')]: {
            top: '0vh',
            height:'80.5vh',

            '& >h1': {
                font: 'normal normal bold 30px/34px Rubik'
            },
            '& >p': {
                font: 'normal normal bold 16px/20px Rubik'
            },
        },
    },
    banner_google_logo: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing('-1.5rem', 0, 0),
        fontSize: '20px',
        '& >img': {
            maxHeight: '50px',
            marginLeft: theme.spacing(-1.1),
        },

        [theme.breakpoints.down('lg')]: {
            '& p': {
                fontSize: '20px',
            }
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
        [theme.breakpoints.down('xs')]: {
            zIndex: 2,
            paddingTop: '8px',
            margin: theme.spacing('-1.5rem', 0, 0),
            width: theme.spacing(42),
            display: 'flex',
            '& p': {
                // 112vw
                width: '8rem',
                flexWrap: '',
                display: 'flex',
                fontSize: '0.7rem',
            },
            '& >img': {
                maxHeight: theme.spacing(4),
                marginLeft: theme.spacing(-4),
            },
        },
    },
    banner_button: {
        zIndex: 4,
        top: '30%',
        textDecoration: 'none',
        width: '16rem',
        height: '2.5rem',
        position: 'relative',
        left: '-2vw',

        '& svg': {
            width: 'auto',
        },

        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            left: '-1.5rem',
            top: '40vh',
            width: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            left: '-1.5rem',
            top: theme.spacing(15),
        },
        [theme.breakpoints.down('414.05')]: {
            top: '30vh',
        },
        [theme.breakpoints.down('375.05')]: {
            top: theme.spacing(28),
        },
        [theme.breakpoints.down('360.05')]: {
            top: '30%',
        },
        [theme.breakpoints.down('320.05')]: {
            zIndex: 3,
            top: '30.5vh',
            width: theme.spacing(20),
            height: '6vh',
            '& span': {
                fontSize: '0.75rem',
            }
        },
        [theme.breakpoints.down('280.05')]: {
            top: theme.spacing(28),
            width: theme.spacing(20),
        },
    },
    banner_img: {
        position: 'absolute',
        bottom: 0,
        top: '0vh',
        right: '-20vw',
        height: '100vh',
        width: 'auto',
        maxWidth: '65vw',
        '& #Ellipse_25':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Ellipse_26':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Ellipse_28':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Path_71':{
            fill: 'rgb(200, 150, 115)',
        },
        '& #Path_73':{
            fill: 'rgb(200, 150, 115)',
        },
        
        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('1600.05')]: {
            
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            zIndex: 3,
            top: '18vh',
            right: '-40vw',
            /* bottom: 0; */
            height: '80vh',
            maxWidth: '-webkit-fill-available',
        },
        [theme.breakpoints.down('768.05')]: {
            top: '22vh',
            right: '-55vw',
            /* bottom: 0; */
            height: '75vh',
        },
        [theme.breakpoints.down('xs')]: {
            top: theme.spacing(28),
            right: '-43vw',
            width: 'auto',
            height: '65vh'
        },
        [theme.breakpoints.down('414.05')]: {
            top: '34vh',
            right: theme.spacing(-38),
            height: '62vh',
        },
        [theme.breakpoints.down('411.05')]: {
        },
        [theme.breakpoints.down('375.05')]: {
            zIndex: 3,
            top: theme.spacing(20),
            right: theme.spacing(-23),
            height: theme.spacing(61),
            width: 'auto',
        },
        [theme.breakpoints.down('360.05')]: {
            top: '27.4%',
            right: '-47vw',
            width: 'auto',
            height: '25%',
            zIndex: 3,
        },
        [theme.breakpoints.down('320.05')]: {
            zIndex: 3,
            top: '42vh',
            right: theme.spacing(-9),
            width: '79vw',
            height: '53vh',


        },
        [theme.breakpoints.down('280.05')]: {
            zIndex: 3,
            top: '31vh',
            right: '-90vw',
            width: 'auto',
            height: '66vh',
        },
    },
    banner_card: {
        display: 'flex',
        zIndex: 2,
        justifyContent: 'space-evenly',
        position: 'relative',
        left: theme.spacing(4),
        bottom: '1rem',
        width: '60%',
        // width: '824px',
        height: '15vw',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        overflow: 'hidden',
        overflowY: 'scroll',

        '& div:not(:last-child):after': {
            content: "''",
            background: 'white',
            position: 'absolute',
            bottom: '5%',
            right: 0,
            height: '90%',
            width: '1px',
        },
        '& p':{
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/22px var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0)',
            color: 'var(--unnamed-color-ffffff)',
            textAlign: 'left',
            font: 'normal normal normal 16px/22px Rubik',
            letterSpacing: '0px',
            color: '#FFFFFF',
            opacity: 1,
        },
        '& h2':{
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 22px/var(--unnamed-line-spacing-27) var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0)',
            color: 'var(--unnamed-color-ffffff)',
            textAlign: 'left',
            font: 'normal normal bold 22px/27px Rubik',
            letterSpacing: '0px',
            color: '#FFFFFF',
            opacity: 1,
            width: '75%',
        },

        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('1680.05')]: {
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('1366.05')]:{
        },
        [theme.breakpoints.down('1280.05')]:{
        },
        [theme.breakpoints.down('1024.05')]: {
            zIndex: 2,
            left: '2vw',
            maxWidth: '60vw',
            height: 'fit-content',
        },
        [theme.breakpoints.down('sm')]: {
            height: '48vh',
            left: '0vw',
            maxWidth: '100%',
            width: '93.5vw',
            flexDirection: 'column',
            padding: theme.spacing(2),
            margin: theme.spacing(2, 2),
            alignSelf: 'stretch',
            '& div:not(:last-child):after': {
                content: "''",
                background: 'white',
                position: 'absolute',
                bottom: 0,
                right: '5%',
                height: '1px',
                width: '90%',
            },
        },
        [theme.breakpoints.down('768.05')]: {
            width: '95%',
        },
        [theme.breakpoints.down('xs')]: {
            top: '10vh',
            height: '132vh',
            bottom: '1rem',
            flexDirection: 'column',
            padding: theme.spacing(2),
            margin: theme.spacing(0, 2),
            alignSelf: 'stretch',
            // Splitter
            '& div:not(:last-child):after': {
                content: "''",
                background: 'white',
                position: 'absolute',
                bottom: 0,
                right: '5%',
                height: '1px',
                width: '90%',
            },
            '& h2': {
                fontSize: '1.5rem',
            },
            '& p': {

            },
        },
        [theme.breakpoints.down('414.05')]: {
            height: '91vh',
            maxWidth: '100%',
            width: 'auto',
        },
        [theme.breakpoints.down('390.05')]: {
            height: '53%',
        },
        [theme.breakpoints.down('375.05')]: {
            height: '90%',
            width: 'fit-content',
        },
        [theme.breakpoints.down('360.05')]: {
            height: '125.4vh',
            top: '-1vh',
        },
        [theme.breakpoints.down('320.05')]: {
            height: '74rem',
        },
        [theme.breakpoints.down('280.05')]: {
            height: '100%',
        },
    },
    banner_card_section: {
        padding: theme.spacing(2, 3),
        flexGrow: 1,
        position: 'relative',
        display: 'grid',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: '33.34%',
        
        [theme.breakpoints.down('xl')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: '30vh',
            maxWidth: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: '30vh',
            maxWidth: '100%',
        },
        [theme.breakpoints.down('280.05')]: {
            height: 'fit-content',
            maxWidth: '100%',
        },
    },
    banner_card_heading: {height: '1rem'},
    sites_searched_section: {
        //height: '25rem',
        margin: '2.5rem 2rem',
        height: '100%',
        // margin: theme.spacing(0, 4),
        padding: theme.spacing(10,6),
        backgroundColor: theme.palette.primary.main,

        '& >div': {
            maxWidth: '100%',
            margin: '20px',
        },
        '& >h2': {
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 22px/var(--unnamed-line-spacing-27) var(--unnamed-font-family-rubik)',
            color: 'var(--unnamed-color-ffffff)',
            textAlign: 'left',
            letterSpacing: '0.44px',
            font: 'normal normal bold 22px/27px Rubik',
            color: '#FFFFFF',
            opacity: 0.4,
            marginLeft: 'inherit',
        },

        [theme.breakpoints.down('xl')]: {

        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('1600.05')]: {
            minHeight: '100%',
            background: 'var(--unnamed-color-02b4a7) 0% 0% no-repeat padding-box',
            background: '#02B4A7 0% 0% no-repeat padding-box',
            borderRadius: '6px',
            opacity: 1,
        },
        [theme.breakpoints.down('1440.05')]: {
            
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2, 2),
            '& div':{
                justifyContent: 'center',
            },
        },
        [theme.breakpoints.down('1024.05')]: {
            top: '1vh',
            height: '100%',
            '& div':{
                justifyContent: 'center',
            },
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(10, 2),
            height: '100%',
            '& >div': {
                justifyContent: 'center',
                maxWidth: '100%',
                padding: theme.spacing(0, 0),
                margin: theme.spacing(0),
            },
            
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(15, 2),

        },
        [theme.breakpoints.down('412.05')]: {
            height: '100%',
        },
        [theme.breakpoints.down('420.05')]: {
        },
        [theme.breakpoints.down('360.05')]: {

        },
        [theme.breakpoints.down('280.05')]: {

        },
    },
    site_icon: {
        height: theme.spacing(15),
        width: theme.spacing(15),
        
        [theme.breakpoints.down('lg')]: {
        },  
        [theme.breakpoints.down('1600.05')]: {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(13),
            width: theme.spacing(13),
        },
        [theme.breakpoints.down('1390.05')]: {
            height: theme.spacing(13),
            width: theme.spacing(13),
        },
        [theme.breakpoints.down('1024.05')]: {
            margin: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            transform: 'scale(0.85)',
            margin: theme.spacing(2),
        },
        [theme.breakpoints.down('600.05')]: {
            height: theme.spacing(13),
            width: theme.spacing(13),
        },
        [theme.breakpoints.down('540.05')]: {
            height: theme.spacing(13),
            width: theme.spacing(13),
        },
        [theme.breakpoints.down('320.05')]: {
            height: theme.spacing(8),
            width: theme.spacing(8),
            margin: theme.spacing(1),
        },
        [theme.breakpoints.down('280.05')]: {
        },
    },
    extension_section: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: theme.spacing(4, 4),

        '& span': {
            fontSize: '50px',
        },

        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('1250.05')]: {
        },
        [theme.breakpoints.down('1235.05')]: {
        },
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.down('sx')]: {
        },
        [theme.breakpoints.down('320.05')]: {
        },
    },
    extension_header_root: {
        flexGrow: 1,
        fontWeight: 900,
        fontSize: '',
        [theme.breakpoints.down('lg')]: {
            order: 1,
        },
        [theme.breakpoints.down('1252.05')]: {
            
        },
        [theme.breakpoints.down('1252.05')]: {
            
        },
        [theme.breakpoints.down('sm')]: {
            order: 1,
            maxWidth: 'unset',
        },
    },
    extension_logo: {
        display: 'inline-flex',
        alignSelf: 'end',
        height: '50px',
        '& svg': {
            position: 'relative',
            top: '21%',
            maxWidth: '6.5rem',
            margin: 'auto 0.4rem',
        },
        '& path#Path_43': {
            fill: theme.palette.error.main,
        },
    },
    extension_caption_root: {
            order: 3,
            margin: theme.spacing(4, 0),
            maxWidth: '45%',
            
        '&>p': {
            color: theme.palette.error.main,
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22px/30px var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0)',
            textAlign: 'left',
            color: 'var(--unnamed-color-575757)',
            font: 'normal normal normal 22px/30px Rubik',
            letterSpacing: '0px',
            color: '#575757',
            opacity: 1,
        },
        [theme.breakpoints.down('lg')]: {

        },
        [theme.breakpoints.down('1600.05')]: {
            order: 3,
            margin: theme.spacing(4, 0),
            maxWidth: '45%',
        },
        [theme.breakpoints.down('1340.05')]: {
            maxWidth: '40%',
        },
        [theme.breakpoints.down('1255.05')]: {

        },
        [theme.breakpoints.down('md')]: {

        },
        [theme.breakpoints.down('sm')]: {
            order: 3,
            margin: theme.spacing(2, 0),
            maxWidth: '100%',
        },
    },
    extension_button: {
        //marginLeft: theme.spacing(-4),
        width: 'auto',
        height: '2.5rem',
        margin: '-20px',

        '& svg': {
            width: 'auto',
        },
        '& span': {
            fontSize: '16px',
        },

        [theme.breakpoints.down('lg')]: {
            order: 3,
        },
    },
    extension_screenshot: {
        height: '100%',
        display: 'flex',
        width: '50%',
        position: 'absolute',
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            order: 2,
            width: '100%',
            margin: theme.spacing(0),
            position: 'relative',
        },
        [theme.breakpoints.down('xs')]: {
        },
        [theme.breakpoints.down('320.05')]: {
        },
    },
    faq_section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(2, 4),
        backgroundColor: theme.palette.grey.main,
        padding: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            padding: theme.spacing(4, 2),
        },
    },
    faq_title: {
        ...theme.typography.sectionTitle,
    },
    faq_accordion_root: {
        alignSelf: 'center',
        margin: theme.spacing(2, 0),
        '& p': {
            color: theme.palette.common.white,
        },
    },
}))