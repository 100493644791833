import React from 'react';
//import { Route, Switch, useLocation } from "react-router-dom";
// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//Custom Imports
import  ExtensionImg from '../assets/extension_v6.svg';
import { ReactComponent as Logo } from '../assets/pikr-logo.svg';
import BookmarkButton from '../components/BookmarkButton';


export default function ExtensionPage() {
    const classes = useStyles();

    return (
        <main className='page-root'>
            <div className={classes.content_root}>
                {/* Extension Section */}
                <div className={classes.extension_section + ' MuiPaper-rounded'}>

                <Typography variant="h3" component='h2' className={classes.extension_header_root} gutterBottom >
                    <span>Get the</span>
                    <span className={classes.extension_logo}>
                        <Logo />
                    </span>
                    <span >extension</span>
                </Typography>


                <img alt="scrnshot" src={ExtensionImg} className={classes.extension_screenshot} />
                {/* <Typography variant='h4'>Extension Screenshot</Typography> */}

                <div className={classes.extension_caption_root}>
                <Typography variant='body1' >
                    Install the Chrome Extension now
                </Typography>
                <Typography variant='body1' gutterBottom >
                    Get your latest piks while continuing your day to day browsing. Our extension provides a quick and easy way to access your latest job opportunities on the fly.
                    Let pikr do the Searching for you.
                </Typography>
                <Typography variant="h6" gutterBottom >
                    Faster. Easier. Hassel free.
                </Typography>
                <div className={classes.extension_button}>
                    <BookmarkButton text='Download now' color='primary' />
                </div>
                </div>

                </div>
            </div>
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(5, 4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            justifyContent: 'center',
        },
    },
    extension_section: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: theme.spacing(4, 4),

        '& span': {
            fontSize: '50px',
        },

        [theme.breakpoints.down('xl')]: {
        },
        [theme.breakpoints.down('lg')]: {
        },
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('1250.05')]: {
        },
        [theme.breakpoints.down('1235.05')]: {
        },
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.down('sx')]: {
        },
        [theme.breakpoints.down('320.05')]: {
        },
    },
    extension_header_root: {
        flexGrow: 1,
        fontWeight: 900,
        fontSize: '',
        [theme.breakpoints.down('lg')]: {
            order: 1,
        },
        [theme.breakpoints.down('1252.05')]: {
            
        },
        [theme.breakpoints.down('1252.05')]: {
            
        },
        [theme.breakpoints.down('sm')]: {
            order: 1,
            maxWidth: 'unset',
        },
    },
    extension_logo: {
        display: 'inline-flex',
        alignSelf: 'end',
        height: '50px',
        '& svg': {
            position: 'relative',
            top: '21%',
            maxWidth: '6.5rem',
            margin: 'auto 0.4rem',
        },
        '& path#Path_43': {
            fill: theme.palette.error.main,
        },
    },
    extension_caption_root: {
            order: 3,
            margin: theme.spacing(4, 0),
            maxWidth: '45%',
            
        '&>p': {
            color: theme.palette.error.main,
            font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22px/30px var(--unnamed-font-family-rubik)',
            letterSpacing: 'var(--unnamed-character-spacing-0)',
            textAlign: 'left',
            // color: 'var(--unnamed-color-575757)',
            // font: 'normal normal normal 22px/30px Rubik',
            // letterSpacing: '0px',
            // color: '#575757',
            opacity: 1,
               
        },
        [theme.breakpoints.down('lg')]: {

        },
        [theme.breakpoints.down('1600.05')]: {
            order: 3,
            margin: theme.spacing(4, 0),
            maxWidth: '45%',
        },
        [theme.breakpoints.down('1340.05')]: {
            maxWidth: '40%',
        },
        [theme.breakpoints.down('1255.05')]: {

        },
        [theme.breakpoints.down('md')]: {

        },
        [theme.breakpoints.down('sm')]: {
            order: 3,
            margin: theme.spacing(2, 0),
            maxWidth: '100%',
        },
    },
    extension_button: {
        //marginLeft: theme.spacing(-4),
        width: 'auto',
        height: '2.5rem',
        margin: '-20px',

        '& svg': {
            width: 'auto',
        },
        '& span': {
            fontSize: '16px',
        },

        [theme.breakpoints.down('lg')]: {
            order: 3,
        },
    },
    extension_screenshot: {
        height: '100%',
        display: 'flex',
        width: '50%',
        position: 'absolute',
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            order: 2,
            width: '100%',
            margin: theme.spacing(0),
            position: 'relative',
        },
        [theme.breakpoints.down('xs')]: {
        },
        [theme.breakpoints.down('320.05')]: {
        },
    },
}))