import React from 'react';
// Material-UI Imports

// Custom Components
import { AuthUserContext, withAuthorization } from '../components/Session';
import SavedPikS from "../components/PikS/SavedPikS";

function SavedPage() {
    return (
        <AuthUserContext.Consumer>
            {authuser => (
                <main className='page-root'>
                    <SavedPikS uid={authuser.uid} />
                </main>
            )}
        </AuthUserContext.Consumer>

    )
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(SavedPage);