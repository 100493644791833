import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Material-UI Imports
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';

// Custom Imports
import { AuthUserContext, withAuthorization } from '../components/Session';
import DeleteAccountCard from '../components/AccountForms/DeleteAccountCard';
import DeleteDocumentCard from '../components/AccountForms/DeleteDocumentCard';
import LoginManagement from '../components/AccountForms/loginManagement';
import PasswordChangeCard from '../components/AccountForms/PasswordChangeCard';
import UserCard from '../components/UserCard';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { EditDetailsTabs } from '../components/UpdateDetails';

import Button from '@material-ui/core/Button';

import * as ROUTES from '../constants/routes';

function AccountPage(props) {
  const classes = useStyles();
  const [Open, setOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);
  
  const handleClickOpen = () => {
        setOpen(true);
  };

  const handleClose = () => {
        setForceOpen(false);      
        setOpen(false);      
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <main className='page-root'>
          {"user_doc" in authUser && authUser.location.country !== ""? null: setForceOpen(true)}
          { forceOpen?  setOpen(true) : null }

          <div className={classes.content}>
            <div className={classes.banner}>
              <Grid container direction='column' alignItems='center' justify='space-around' spacing={2}>
                <Grid item >
                  <Avatar alt={authUser.username} src={authUser.avatar} className={classes.avatar} />
                </Grid>
                
                <Grid item >
                  <Typography variant='h3' >
                    {authUser.username}
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <div className={classes.container}>
              <Grid container spacing={3} >
                <Grid item xs={12} md={6} >
                  <UserCard authUser={authUser} />
                </Grid>
                <Grid item xs={12} md={6} >
                  <PasswordChangeCard />
                </Grid>
                <Grid item xs={12} md={6} >
                  <LoginManagement authUser={authUser} />
                </Grid>
                <Grid item xs={12} md={6} height="100%" >
                  <DeleteDocumentCard  uid={authUser.uid} docName={!authUser.user_doc ? ("no doc") : (authUser.user_doc.docName)} />
                </Grid>
              </Grid>
              <DeleteAccountCard />
            </div>
          </div>

          <Dialog fullWidth maxWidth='md' open={Open} onClose={handleClose} >
            <DialogTitle disableTypography>
                <Typography align='center' variant='h5'>
                    Update your Details
                </Typography>
            </DialogTitle>
            <DialogContent >
                <EditDetailsTabs authUser={authUser} />
            </DialogContent>
            <DialogActions>
                <Divider />
                <Button  onClick={handleClose} component={Link} to={ROUTES.HOME} color='secondary'>
                  <div class="tooltip">
                  	close
                    <span class="tooltiptext tooltip-left">Before you can continue, please add location and resume</span>
                  </div>
                </Button>
            </DialogActions>
          </Dialog>
        </main>
      )}
      
    </AuthUserContext.Consumer>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  banner: {
    backgroundColor: theme.palette.secondary.main,
    opacity: '0.8',
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  },
  container: {
    height: '100%',
    width: '100%',
    margin: theme.spacing(2, 0),
    // padding: theme.spacing(2),
    // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
  },
  edit_button: {
      color:'black',
      position: 'relative',
      top: 0,
      right: 0,
      backgroundColor: theme.palette.grey[300],
      height: '1.5rem',
      width: '3rem',
      borderRadius: 0,
      fontSize: 'x-small'
  },
}));

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);