import React from 'react';
// Material-ui Imports
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function PrivacyPolicyPage() {
    const classes = useStyles();
    return (
        <main className='page-root'>
            <div className={classes.content_root}>
                <Typography
                    className={classes.title}
                    variant='h3'
                    compoent='h2'
                    gutterBottom >
                    Privacy Policy
                </Typography>
                <Paper className={classes.page} >
                    <ol>
                        <Typography variant='body2' component='li' gutterBottom>
                            South African law and jurisdiction applies with respect to the content of this site and the
                            information submitted.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            Except as set out herein and where required or permitted by law or a court of competent
                            jurisdiction, we will not reveal any personal data about you to any third party.
                        </Typography>


                        <Typography variant='body2' component='li' gutterBottom>
                            You consent to us processing your information for the purposes of providing you with our
                            services and maintaining our records. You also consent to your data being disclosed to any
                            person, firm or company to whom we sell the whole or a substantial part of our business or to
                            whom we may transfer any part of our rights or obligations.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            Certain links contained within this website may lead to external servers and sites which are
                            maintained by third parties unconnected to us and over which we have no control. We can
                            accept no responsibility or liability in respect of any material contained within those sites or
                            servers.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            Processing may take place via electronic means using the Internet and may involve transfer to
                            countries outside the United States of America, European Economic Area or South Africa, which
                            do not have equivalent laws relating to data protection.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            To the extent permitted by law, we do not accept liability for any damages (including, without
                            limitation, damages for any consequential loss or loss of business opportunities, or loss of profits)
                            howsoever arising and whether in contract, tort or otherwise from the use of or inability to use the
                            site or its contents, or from any action or omission taken as a result of using the site or its
                            contents.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            Where the site contains advertising submitted by third parties, please note that those
                            advertisers are responsible for ensuring that the material submitted for inclusion on the site
                            complies with all legal requirements.
                        </Typography>

                        <Typography variant='body2' component='li' gutterBottom>
                            We are unable to offer any guarantee that the contents of the site are free from viruses or
                            similar which have destructive or contaminating properties and shall therefore have no liability in
                            respect thereof. We regularly check the site for viruses using the leading proprietary virus
                            checking software.
                        </Typography>
                    </ol>
                </Paper>
            </div>
        </main>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        margin: '3% auto 5%',
        width: '65%',
    },
    title: {
        textAlign: 'start',
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    page: {
        padding: theme.spacing(5, 8),
        color: theme.palette.text.primary,
        '& ol': {
            paddingInlineStart: '1rem',
            paddingInlineEnd: '1rem'
        },
        '& ol li': {
            paddingInlineStart: '1rem'
        },
        '& ol li::marker': {
            fontWeight: 500,
        },
    },
}));