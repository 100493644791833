export const sites = [
    '/searched-sites/Pikr_SiteIcons_CareerJunction.svg',
    '/searched-sites/Pikr_SiteIcons_Careers24.svg',
    '/searched-sites/Pikr_SiteIcons_CurriculumVitae.svg',

    '/searched-sites/Pikr_SiteIcons_Glassdoor.svg',
    '/searched-sites/Pikr_SiteIcons_Indeed.svg',
    '/searched-sites/Pikr_SiteIcons_JobIndex.svg',
    //'/searched-sites/Pikr_SiteIcons_LinkedIn.svg',
    
    '/searched-sites/Pikr_SiteIcons_Pnet.svg',
    '/searched-sites/Pikr_SiteIcons_TotalJobs.svg',
    '/searched-sites/Pikr_SiteIcons_ZipRecruiter.svg',
]
//'/searched-sites/Pikr_SiteIcons_Monster.svg', 
//'/searched-sites/executiveplacements_icon.jpg',

// export const siteLinks = {
//   "querySiteLinks": {
//     "monster": {
//       "uriRef": ""
//     },
//     "careerjet": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcareer-jet_icon.png?alt=media&token=1f33da3c-0814-4b3c-a79f-0ab663bf17e1"
//     },
//     "snagajob": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-dev-eda77.appspot.com/o/Site_Assets%2Fimages%2Fquery_site_icons%2Fsnagajob_icon.png?alt=media&token=d1fffa2d-3602-470c-adfa-7a179787d090"
//     },
//     "jobs2careers": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fjob2career_icon.jpg?alt=media&token=3c51e6da-df43-4565-8e36-c4d0566f9024"
//     },
//     "snagajobs": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fsnagajob_icon.png?alt=media&token=d87bb875-b2a4-44e4-9b22-6ee6ec24ef90"
//     },
//     "jobvine": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fjobvine.png?alt=media&token=639a304d-7e3b-4020-ba6a-ae1fe63003e3"
//     },
//     "jobindex": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fjobindex_icon.png?alt=media&token=a1e382f9-b995-4684-854d-c9f4809ee102"
//     },
//     "cvlibraryr": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcv-library_icon.png?alt=media&token=665702b7-9d33-472f-bbf3-84313901cf5f"
//     },
//     "linkedin": {
//       "uriRef": "linkedin"
//     },
//     "pnet": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fpnet_icon.png?alt=media&token=d85e6c96-d91e-4da9-ad6f-05a7ae353267"
//     },
//     "careers24": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcareer24_icon.jpg?alt=media&token=9544ceba-3c65-4d8b-ba85-77e723598b8d"
//     },
//     "careerjet4": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcareer-jet_icon.png?alt=media&token=1f33da3c-0814-4b3c-a79f-0ab663bf17e1"
//     },
//     "careerjunction": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcareer-junction_icon.jpg?alt=media&token=79825e2b-0637-4fa0-999f-bc16545e0a71"
//     },
//     "totaljobs": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Ftotaljobs_icon.png?alt=media&token=1042dc6b-924b-418d-91ed-18885f8494e3"
//     },
//     "indeed": {
//       "uriRef": "indeed"
//     },
//     "adzuna": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fadzuna_icon.png?alt=media&token=64af211c-455b-40a9-a901-6bcdc26364a0"
//     },
//     "glassdoor": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fglassdoor_icon.png?alt=media&token=c28bfc22-1346-4f5c-bd91-8f56c0382cbf"
//     },
//     "cvlibrary": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fcv-library_icon.png?alt=media&token=665702b7-9d33-472f-bbf3-84313901cf5f"
//     },
//     "resumelibrarys": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fresume-library_icon.jpg?alt=media&token=71029667-7af9-49ca-b9bb-302a4ff91f67"
//     },
//     "ziprecruiter": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fziprecruiter_icon.png?alt=media&token=327df295-5981-4712-973e-dfc4c2b21506"
//     },
//     "resumelibrary": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Fresume-library_icon.jpg?alt=media&token=71029667-7af9-49ca-b9bb-302a4ff91f67"
//     },
//     "reed": {
//       "uriRef": "https://firebasestorage.googleapis.com/v0/b/pikr-prod.appspot.com/o/Site_Assets%2Fimages%2Freed_icon.png?alt=media&token=066ef15a-74e8-4002-9f0d-abc4891ad4b2"
//     }
// }