import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
//Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
// Custom Components
import { withFirebase } from '../Firebase';

// import { VariantUplaodCVForm ,UplaodCVForm } from '../FileUpload'


const useStyles = makeStyles((theme) => ({
    card: {
        height:"100%",
        minHeight: '30vh'
    },
    cardAction: {
        justifyContent: 'center',
    },
    noDoc_text: {
        color: 'secondary'
    },
    deleteBtn: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.text.primary,
        },
    },
}));

function DeleteDocumentCard(props) {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [docExsists, setDocExsists] = useState((props.docName !== "no doc") ? (true) : (false));

    const deleteCV = () => {
        props.firebase
            .userStorage(props.uid)
            .child(props.docName)
            .delete()
            .then(() => {
                return props.firebase
                    .user(props.uid + '/user_doc')
                    .remove()
            })
            .then(() => {
                // Analytics
                props.firebase.analytics.logEvent('cv_deleted');
                //////////////////////////////////
                enqueueSnackbar("Your CV has been Deleted.", {
                    variant: 'success',
                });
                setDocExsists(false);
                // console.log('Success, Deleted CV')
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar("Something went wrong, please try again later.", {
                    variant: 'error',
                });
            });
    }

    useEffect(() => {
        setDocExsists((props.docName !== "no doc") ? (true) : (false))
        return () => {
            return true;
        }
    }, [props.docName]);

    return (
        <Card className={classes.card} >
            <CardContent >
                <Typography variant='h4' align='center' gutterBottom >Delete CV</Typography>
                { !docExsists?
                    (<div>
                        <Typography hidden={docExsists} color='secondary' variant='body2' align='center'>
                            You don't have a CV uploaded. 
                            This will cause results to be less accurate. 
                        </Typography>
                    </div>)
                :
                    <Typography variant='body2' align='center' >
                        You can delete the CV you have uploaded.
                    </Typography>
                    
                }
            </CardContent>
            <CardActions className={classes.cardAction} >
                <Button className={classes.deleteBtn} variant='contained' size="large" disabled={!docExsists} onClick={deleteCV} >Delete CV</Button>
            </CardActions>
        </Card>
    )
}

export default withFirebase(DeleteDocumentCard)