import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import LinkedIn from "./LinkedinClient";
// Material-UI Components
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class SignInLinkedinBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            errorMessage: '',
            loading: false
        };
    }

    toggleLoading = () => {
        this.setState((state, _props) => ({
            loading: !state.loading
        }));
    }

    /*
    ** @code = authorization code from linkedin api
    ** @redirectUri = redirect uri from linkedin api
    ** @error = error message sign in failed
    */
    callbackLinkedIn = (error, code, _redirectUri, state, scope) => {
        this.toggleLoading();
        if (error) {
            // signin failed
            this.setState({
                errorMessage: error
            })
            this.toggleLoading();
            console.log(error);
        } else {
            // Obtain authorization token from linkedin api
            const getToken = this.props.firebase.functions.httpsCallable("linkedinAuth");
            // console.log("Getting Token");
            // console.log("State recieved:  " + state);

            getToken({ code: code, state: state, scope: scope, redirect: _redirectUri })
                .then(data => {
                    return this.props.firebase.doSignInWithLinkedin(data.data.customToken)
                })
                .then(_socialAuthUser => {
                    // Analytics
                    this.props.firebase.analytics.logEvent('login', {
                        method: 'LinkedIn'
                    });
                    //////////////////////////////
                    this.toggleLoading();
                    this.props.history.push(ROUTES.ACCOUNT);
                })
                .catch(error => {
                    this.toggleLoading();
                    console.log(error);
                })

        }
    };

    render() {
        var { classes } = this.props;

        return (
            <div className={classes.button_root}>
                <LinkedIn
                    className={classes.button}
                    clientId="777u8y5rzurt6k"
                    callback={this.callbackLinkedIn}
                    scope={["r_emailaddress", "r_liteprofile"]}
                    text="Continue With LinkedIn"
                />
                {!!this.state.errorMessage ? (this.state.errorMessage) : (null)}
                <Backdrop className={classes.backdrop} open={this.state.loading}>
                    <CircularProgress
                        variant="determinate"
                        className={classes.bottom}
                        size={40}
                        thickness={4}
                        value={100}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.top}
                        classes={{
                            circle: classes.circle,
                        }}
                        size={40}
                        thickness={4}
                    />
                </Backdrop>
            </div>
        );
    }
}

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    bottom: {
        color: 'lightGrey',
    },
    top: {
        color: '#7A43BC',
        animationDuration: '800ms',
        position: 'absolute',
    },
    circle: {
        strokeLinecap: 'round',
    },
    button_root: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: theme.spacing(0),
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,//'#0e76a8',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
});

const SignInLinkedIn = compose(
    withStyles(useStyles),
    withRouter,
    withFirebase,
)(SignInLinkedinBase);

export default SignInLinkedIn;