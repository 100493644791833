import React, { useState } from 'react';
// Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import MUI_Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// Material-UI Icons
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function Accordion(props) {
    const { Items } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Divider />
            {Items.map((item, key) => (
                <React.Fragment key={key}>
                    <MUI_Accordion
                        expanded={expanded === key}
                        onChange={handleChange(key)}
                        className={classes.faq_accordion}
                    >
                        <AccordionSummary expandIcon={expanded === key ? <RemoveIcon /> : <AddIcon />} >
                            <Typography >
                                {item.q}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {item.a}
                            </Typography>
                        </AccordionDetails>
                    </MUI_Accordion>
                    <Divider hidden={expanded === key} />
                </React.Fragment>
            ))}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    faq_accordion: {
        '& .MuiIconButton-root': {
            color: theme.palette.common.white
        },
    },
}))


export default Accordion;