import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Material-ui Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
//import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Custom Components
import { EditDetailsTabs } from '../UpdateDetails';
//import { UploadAvatar } from "../FileUpload";

import * as ROUTES from '../../constants/routes';

export default function UserCard(props) {
    const classes = useStyles();
    const [Open, setOpen] = useState(false);

    const LocalArea = props.authUser.location.localArea ? (`${props.authUser.location.localArea}, `) : ('');
    const Region = props.authUser.location.region ? (`${props.authUser.location.region}, `) : ('');
    const Country = props.authUser.location.country ? (`${props.authUser.location.country} `) : ('');
    //const userAvatar = props.authUser.avatar ? (`${props.authUser.avatar}`) : ('/lOGO_ICON_auto_x1_colored.png')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <Card className={classes.card}>
                    <div className={classes.card_content}>
                        <Button className={classes.edit_button} size='small' onClick={handleClickOpen}>edit</Button>

                        <div className={classes.title}>
                            <Typography variant='h6' component='p' >{props.authUser.username}</Typography>
                            <Typography variant='subtitle1' component='span' >{!!props.authUser.jobTitle ? (props.authUser.jobTitle) : ('')}</Typography>
                        </div>

                        <div className={classes.details}>
                            <div>
                                <Typography color='textSecondary' variant='caption' component='p' >EMAIL</Typography>
                                <Typography variant='body2' >{props.authUser.email}</Typography>
                            </div>
                            <div>
                                <Typography color='textSecondary' variant='caption' component='p' >LOCATION</Typography>
                                <Typography variant='body2' >
                                    {LocalArea}
                                    {Region}
                                    {Country}
                                </Typography>
                            </div>

                        </div>
                    </div>
                </Card >
            </div>

            <Dialog fullWidth maxWidth='md' open={Open} >
                <DialogTitle disableTypography>
                    <Typography align='center' variant='h5'>
                        Update your Details
                    </Typography>
                </DialogTitle>
                <DialogContent >
                    <EditDetailsTabs authUser={props.authUser} />
                </DialogContent>
                <DialogActions>
                    <Divider />
                    <Button onClick={handleClose} color='secondary'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >

    )
}

const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        position: 'relative',
        //maxWidth: '20vw',
        minWidth: '20vw',
        textAlign: 'start',

        [theme.breakpoints.down('sm')]: {
            //height: '25vh',
            maxWidth: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
        },
        [theme.breakpoints.down('xs')]: {
            //height: '35vh',
        },
    },
    edit_button: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme.palette.grey[300],
        height: '1.5rem',
        width: '3rem',
        borderRadius: 0,
        fontSize: 'x-small'
    },
    avatar: {
        height: 200,
        [theme.breakpoints.down('sm')]: {
            height: 250,
            width: '40%',
            position: 'relative',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    card_content: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '70%',
            padding: theme.spacing(0, 4),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    title: {
        padding: theme.spacing(1, 0),
        '& >p': {
            fontWeight: 'bold',
            lineHeight: 1,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& >div': {
            marginBottom: theme.spacing(2),
        }
    },
    container: {
        height: '100%',
        width: '100%',
      //margin: theme.spacing(2, 0),
      // padding: theme.spacing(2),
      // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
    }
}));


// {!!props.authUser.avatar ? (
//     <Avatar style={styles.avatar} variant="square">
//         <img alt='Profile' style={{ height: '100%', width: '100%' }} src={props.authUser.avatar} />
//     </Avatar>
// ) : (
//     <Avatar style={styles.avatar} variant="square" >
//         <PersonIcon style={{ height: '50%', width: '50%' }} />
//     </Avatar>
// )}