import React from 'react';
import { Link } from 'react-router-dom';
// Material-ui Components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Social media Icons

import { ReactComponent as Facebook } from '../../assets/social-icons/social_icons/Pikr_FooterIcons_Facebook.svg'
import { ReactComponent as Instagram } from '../../assets/social-icons/social_icons/Pikr_FooterIcons_Instagram.svg'
import { ReactComponent as LinkedIn } from '../../assets/social-icons/social_icons/Pikr_FooterIcons_LinkedIn.svg'
import { ReactComponent as Twitter } from '../../assets/social-icons/social_icons/Pikr_FooterIcons_Twitter.svg'

// Custom Imports
import { ReactComponent as Logo } from '../../assets/pikr-logo.svg';
import * as ROUTES from '../../constants/routes';
import * as LINKS from '../../constants/links';


export default function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footer_root}>
            <div className={classes.footer_logo}>
                <Logo />
            </div>

            <div className={classes.footer_content}>
                <div className={classes.footer_section} >
                    <Typography variant='button' component='p' gutterBottom>
                        Chat to us
                    </Typography>
                    <Typography
                        variant='body2'
                        component='a'
                        href='mailto:support@pikr.tech'
                        className={classes.footer_links}
                    >
                        support@pikr.tech
                    </Typography>
                </div>

                <div className={classes.footer_section} >
                    <Typography
                        variant='button'
                        component='p'
                        gutterBottom
                    >
                        Legal
                    </Typography>
                    <Typography
                        variant='body2'
                        component={Link}
                        to={ROUTES.PRIVACY_POLICY}
                        className={classes.footer_links}
                    >
                        Privacy Policy
                    </Typography>
                    <Typography
                        variant='body2'
                        component={Link}
                        to={ROUTES.USER_AGREEMENT}
                        className={classes.footer_links}
                    >
                        User Agreement
                    </Typography>
                </div>

                <div className={classes.footer_section} >
                <Typography variant='button' component='p' gutterBottom>
                    More
                </Typography>
                <Typography
                        variant='body2'
                        component={Link}
                        to={ROUTES.EXTENSION}
                        className={classes.footer_links}
                    >
                        Extension
                    </Typography>
                <Typography
                        variant='body2'
                        component={Link}
                        to={ROUTES.FAQ}
                        className={classes.footer_links}
                    >
                        FAQ
                    </Typography>
                </div>
                <div className={classes.footer_section} >
                    <Typography
                        variant='button'
                        component='p'
                        gutterBottom >
                        Follow us
                    </Typography>
                    <div className='socialLinks'>

                        <Typography component='a'
                            href={LINKS.INSTAGRAM}
                            id='instagram'
                            className={classes.social_links}>
                            <Instagram />
                        </Typography>

                        <Typography component='a'
                            href={LINKS.LINKEDIN}
                            id='linkedin'
                            className={classes.social_links}>
                            <LinkedIn />
                        </Typography>

                        <Typography component='a'
                            href={LINKS.FACEBOOK}
                            className={classes.social_links}
                            id='facebook'
                        >
                            <Facebook />
                        </Typography>

                        <Typography component='a'
                            href={LINKS.TWITTER}
                            id='twitter'
                            className={classes.social_links}>
                            <Twitter />
                        </Typography>


                    </div>
                    {/* <img className={classes.google_logo} src="/logo_lockup_cloud_rgb.png" alt="Google Cloud Partner" /> */}
                </div>
            </div>
        </footer>
    )
}

const useStyles = makeStyles(theme => ({
    footer_root: {
        zIndex: 10,
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #F2F2F2',
        padding: theme.spacing(7),
        boxSizing: 'border-box',
        position: 'relative',
        backgroundColor: 'white',
        bottom: 0,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: theme.spacing(6, 4),
        },
    },
    footer_logo: {
        position: 'relative',
        left: theme.spacing(2),
        height: '8rem',
        width: '16rem',
        alignSelf: 'center',
        '& svg': {
            maxHeight: '3.5rem',
            width: 'auto',
        },
        '& #Path_43': {
            fill: theme.palette.primary,
        },
        [theme.breakpoints.down('sm')]: {
            left: theme.spacing(0),
            alignSelf: 'start',
            height: '6rem',
            width: '12rem',
        },
    },
    footer_content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        maxWidth: '50vw',
        flexGrow: 1,
        padding: theme.spacing(2, 0),
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            margin: 0,
        },
    },
    footer_section: {
        flexWrap: 'wrap',
        flexGrow: 1,
        margin: theme.spacing(1),
        '& >p': {
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('xs')]: {
            margin: 0,
        }
    },
    google_logo: {
        maxHeight: theme.spacing(6),
        border: '0.5px solid ' + theme.palette.grey[500],
        borderRadius: theme.shape.borderRadius,
    },
    footer_links: {
        display: 'block',
        textDecoration: 'none',
        color: theme.palette.grey[600],
    },
    social_links: {
        boxSizing: 'border-box',
        borderRadius: theme.shape.borderRadius,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 25,
        width: 25,
        padding: '0.2rem',
        margin: theme.spacing(0, 0.5, 1),
        //'&#instagram': {
        //    backgroundColor: '#E1306C',
        //},
        //'&#linkedin': {
        //    backgroundColor: '#0e76a8',
        //},
        //'&#facebook': {
        //    backgroundColor: '#3b5998',
        //},
        //'&#twitter': {
        //    backgroundColor: '#00acee',
        //}
    }
}))