import React from 'react';

//Material-UI Components
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

//Custom Component
import PasswordChangeForm from "../PasswordChange";


const useStyles = makeStyles((theme) => ({
    card: {
        minHeight: '40vh'
    }
}));

export default function PasswordChangeCard() {
    const classes = useStyles();

    return (
        <Card className={classes.card} >
            <CardContent >
                <Typography variant='h4' align='center' gutterBottom >Change Password</Typography>
                <PasswordChangeForm />
            </CardContent>
        </Card>
    )
}
