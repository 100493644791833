import React from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//Custom Imports
//import { ReactComponent as SignInImg } from '../assets/sign-in-img.svg'
import { AuthUserContext, withAuthorization } from '../components/Session';

import {UplaodCVForm, VariantUplaodCVForm} from '../components/FileUpload/index';
import { Button } from '@material-ui/core';
import * as ROUTES from '../constants/routes';


function UploadCVPage() {
    const classes = useStyles();
    const location = useLocation();
    console.log(location);

    const next = () => {

    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <main className='page-root' style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <div className={classes.content_root}>
                        

                        <div className={classes.form_root}>
                        {"user_doc" in authUser?
                        <div >
                        <Typography align='center'>
                            We are checking to see if we can match your resume to any jobs.
                        </Typography>
                        <Typography align='center'>
                            Click Next
                        </Typography>
                        </div>
                        :
                        <Typography>
                            Upload a resume so that we can start analysing it
                        </Typography>
                        }
                        {"user_doc" in authUser?
                        <VariantUplaodCVForm authuser={authUser}></VariantUplaodCVForm>
                        :
                        <UplaodCVForm authuser={authUser}></UplaodCVForm>}
                        </div>

                        <div id='next'className={classes.next}>
                            {"user_doc" in authUser?
                            <Button
                             
                            className={classes.altNext} onClick={next()} component='label' variant="contained" disableElevation>
                                <Link to={ROUTES.ACCOUNT}>
                                    Next
                                </Link>
                            </Button>
                            :
                            <Button 
                            disabled
                            className={classes.next} onClick={next()} component='label' variant="contained" disableElevation>
                                <Link to={ROUTES.ACCOUNT}>
                                    Next
                                </Link>
                            </Button>
                            }
                        </div>
                    </div>
                </main>
            )}
        </AuthUserContext.Consumer>
    )
}

const useStyles = makeStyles(theme => ({
    content_root: {
        flexDirection: "column",
        height: "62.5vh",
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(5, 4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 2),
            justifyContent: 'center',
        },
    },
    signIn_img: {
        position: 'absolute',
        top: '-6vh',
        left: '-1%',
        width: '55%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    form_root: {
        height: 'inherit',
        position: 'relative',
        maxWidth: '45vw',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        '& >span': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: theme.spacing(0, 2),
            '&::before, &::after': {
                content: '""',
                margin: 'auto 1rem',
                borderBottom: '1px solid',
                flex: '1 1',
            },
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '85%',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            padding: theme.spacing(2, 0),
        },
    },
    pageText: {  
        display: 'flex',
        justifyContent: 'center',
    },
    next: {
        display: 'flex',
        justifyContent: 'center',
        //width: '80%',
        '& a': {
            color: '#757575',
            textDecoration: 'none',
        },
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    altNext: {
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        justifyContent: 'center',
        //width: '80%',
        '& a': {
            color: '#757575',
            textDecoration: 'none',
        },
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },

}))

const condition = authUser => !!authUser;

export default withAuthorization(condition)(UploadCVPage);