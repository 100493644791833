import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
//  Material-UI components
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

//Custom Imports
import BookmarkButton from '../BookmarkButton';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      error: null
    };
  }

  onSubmit = event => {
    const { firstName, lastName, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).update({
          email: email,
          username: `${firstName} ${lastName}`,
          location: {
            localArea: "",
            country: "",
            countryGlCode: "",
            countryShortName: "",
            region: ""
          },
          altLocation: {
            localArea: "",
            country: "",
            countryGlCode: "",
            countryShortName: "",
            region: ""
          },
          socialPages: {
            facebook: "",
            github: "",
            linkedin: "",
            personal: ""
          },
          Skills: [],
          sector: "",
          roles: {
            CANDIDATE: true,
          },
        });
      })
      .then(() => {
        // Analytics
        this.props.firebase.analytics.logEvent('sign_up', { method: 'email-password' });
        ////////////////////////////////////////
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.UPLOADCV);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });
    /*    Email Verification
          .then(() => {
            return this.props.firebase.doSendEmailVerification();
          })
    */
    event.preventDefault();

  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      passwordOne,
      passwordTwo,
      error
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstName === '' ||
      lastName === '';

    const { classes } = this.props;

    return (
      <div className={classes.paper}>

        <Typography component="h1" variant="h4">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={this.onSubmit}>
          <Grid container id="signUpFormNameGrid" className={classes.form_inputNames_root} spacing={1}>
            <Grid item xs={12} md={6} id="signUpFormFirstNameGridItem">
              <TextField
                id="firstName"
                variant="filled"
                margin="dense"
                required
                fullWidth
                label="First Name"
                name="firstName"
                autoComplete="first-name"
                autoFocus
                value={firstName}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={6} id="signUpFormSecondNameGridItem">
              <TextField
                id="lanstName"
                variant="filled"
                margin="dense"
                required
                fullWidth
                label="Last Name"
                name="lastName"
                autoComplete="last-name"
                value={lastName}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>

          <TextField
            id="email"
            variant="filled"
            margin="dense"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={this.onChange}
          />
          <TextField
            id="passwordOne"
            variant="filled"
            margin="dense"
            required
            fullWidth
            label=" New Password"
            name="passwordOne"
            type="password"
            autoComplete="new-password"
            value={passwordOne}
            onChange={this.onChange}
          />
          <TextField
            id="passwordTwo"
            variant="filled"
            margin="dense"
            required
            fullWidth
            label="Confirm Password"
            name="passwordTwo"
            type="password"
            autoComplete="current-password"
            value={passwordTwo}
            onChange={this.onChange}
          />
          <ButtonBase
            disabled={isInvalid}
            type="submit"
            className={classes.submit}
          >
            <BookmarkButton text='Submit' color='primary' disabled={isInvalid} />
          </ButtonBase>

          {error && <p>{error.message}</p>}
          <Grid container justify="center">
            <Grid item>
              <Typography variant='subtitle2'className={classes.hyperlink}>
                <Link to={ROUTES.SIGN_IN} >
                  Already have an account? Sign in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <Button className='' color="inherit" component={Link} to={ROUTES.SIGN_UP} spacing={0} >Sign Up</Button>
);

const useStyles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]:{
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  form: {
    width: '80%',
    margin: theme.spacing(1, 0),
    '&> div':{
      position: 'sticky',
    }
  },
  form_inputNames_root: {
    // '&> *': {
    //   padding: theme.spacing(0, -0.5),
    // },
    '&> div': {
      '&> div': {
        position: 'sticky',
      },
    },

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      width: '100%',
      '&> div': {
        padding: theme.spacing(0) + '!important',
      },
    },
  },
  hyperlink:{
    '& a':{
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    }
  },
  submit: {
    margin: theme.spacing(2, 0),
    height: '2rem',
    width: '70%',
    left: '-15%',
    [theme.breakpoints.down('xs')]: {
      left: theme.spacing(-3),
      width: '85%',
    },
  },
});

const SignUpForm = compose(
  withStyles(useStyles),
  withRouter,
  withFirebase,
)(SignUpFormBase);

export { SignUpForm, SignUpLink };
