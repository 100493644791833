import React from 'react';
// Material-UI Imports
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import { AuthUserContext, withAuthorization } from '../components/Session';
//import UserCard from '../components/UserCard';
import LatestPiks from '../components/PikS/LatestPikS';
import SuggestedPiks from '../components/PikS/Recommender';

function DashboardPage() {
    const classes = useStyles();
    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <main className='page-root'>
                    <div className={classes.content}>
                        <div className={classes.piks_root}>
                            <LatestPiks uid={authUser.uid} userDoc={authUser.user_doc ? authUser.user_doc : null} />
                        </div>
                        {/* <div className={classes.piks_root2}>
                            <SuggestedPiks uid={authUser.uid} userDoc={authUser.user_doc ? authUser.user_doc : null}/>
                        </div> */}
                    </div>
                </main>
            )}
        </AuthUserContext.Consumer>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(4),
        margin: '0 0 200px 0',
        alignItems: 'stretch',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(4, 2),
        },
    },
    userCard_root: {
        // width: '25vw',
        minWidth: '20vw',
        left: 0,
        alignSelf: 'start',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            alignSelf: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    piks_root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '80vh',
        maxWidth: '100vw',
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 0),
            paddingLeft: theme.spacing(0),
        },
    },
    piks_root2: {
        margin: '5rem 0 0 0',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '80vh',
        maxWidth: '100vw',
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 0),
            paddingLeft: theme.spacing(0),
        },
    },
}));

const condition = authUser => !!authUser;

export default withAuthorization(condition)(DashboardPage);